@import '../../scss/partials/links';

.titleBubble {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 2px 1px rgba(55, 55, 55, 0.15);
    border-radius: 20px;
    margin-bottom: 20px;

    .topRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        .titleText {
            @include text(20px);
            margin-top: 20px;
            padding-bottom: 6px;
            margin-bottom: 6px;
            padding-left: 30px;
            padding-right: 30px;
            color: #464646;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .personWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding-left: 30px;
        padding-right: 30px;

        .person {
            max-height: 100%;
            @include my(0px, 20px);
            overflow: hidden;
            text-wrap: nowrap;

            .profilePicture {
                width: 26px;
                height: 26px;
                color: $light-gray;
                padding-top: 0px;
                margin-right: 4px;
                border-radius: 50%;
            }

            .textWrapper {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
                max-width: 200px;

                .basicText {
                    @include text(14px);
                    margin-left: 4px;
                    padding-top: 0px;
                    padding-bottom: 6px;
                    color: #464646;
                }

                .basicText,
                .usernameText {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .usernameText {
                    @include text(14px);
                    color: #3EA680;
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }
        }
    }
}
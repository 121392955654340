@import "../../../scss/partials/links";

.bubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    width: 100%;
    height: 32px;
    overflow: hidden;

    background-color: $light-gray-btn;
    border-radius: 100px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid transparent;
    transition: background-color 0.2s, border-color 0.2s;

    @include px(8px, 8px);
    @include py(4px, 4px);

    .image {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .name {
        @include text(12px);
        color: $text-primary;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
        width: 100%;
    }

    .spacer {
        flex-grow: 1;
    }

    .closeIcon {
        color: $text-primary;
        flex-shrink: 0;
        width: 16px;
    }

    &:hover {
        background-color: darken($light-gray-btn, 5%);
    }

    &.selected {
        background-color: $light-green;
        border: 1px solid $dark-green;
        transition: none;

        .spacer {
            display: none;
        }
    }
}
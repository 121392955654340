@import "../../../../scss/partials/links";

.TimeRangeWrap {
    margin-top: 25px;

    width: calc(100% - 30px);
    @include mx(15px, 15px);

    @include resp(calc($sm + 30px)) {
        max-width: $sm;
        @include mx(calc((100% - $sm) / 2), calc((100% - $sm) / 2));
    }
    
    .Row {
        justify-content: center;
        @include resp($lg) {
            column-gap: 45px;
        }
    }
    // .InputWrap {
    //     // @include resp($lg) {
    //     //     @include px(0px!important, 0px!important);
    //     //     max-width: 175px;
    //     // }
    // }
    .Input {
        text-align: center;
        cursor: pointer;
        position: relative;
        height: 40px;
        background-color: $light-gray-btn;
        color: $light-gray-letter;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 250px;

        transition: color 0.2s;

        @include text(12px);
        @include resp($lg) {
            // max-width: 175px!important;
        }
        .timeList {

            position: absolute;
            // top: 50px;
            top: -155px;
            width: 100%;

            overflow: hidden;

            background: #f5f5f5;
            border-radius: 10px;
            z-index: 500;

            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @include resp ($lg) {
                top: 50px;
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .hourWrap {
            padding: 10px;
            height: 130px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                display: none;
            }
            width: 50%;
            .Time {
                @extend %text-12;
                @include py(12px, 12px);
                height: 40px;
                @extend %center;
                &.active {
                    background-color: $dark-green;
                    border-radius: 5px;
                    color: white;
                }
            }
        }

        .amPm {
            flex: 1;
            @extend %text-12;
            padding: 10px;
            // display: flex;
            .lap {
                @extend %center;
                width: 100%;
                height: 40px;
                text-transform: uppercase;
                border: none;
                background-color: transparent;
                &.active {
                    background-color: $dark-green;
                    border-radius: 5px;
                    color: white;
                }
            }
        }
    }

    .Input:hover, .Input.Active {
        color: black;
    }
}

@import "../../../scss/partials/links";

.headerRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
    gap: 10px;

    &.hide {
        visibility: hidden;
    }

    .spacer {
        flex-shrink: 0;
        flex-grow: 0;
        height: 100%;
    }

    .header {
        flex-grow: 1;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;

        .date {
            @include text(10px);
            color: $dark-gray;
        }

        .day {
            @include text(12px);
            color: $dark-gray;
        }
    }
}
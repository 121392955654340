@import "../../scss/partials/links";

.MeetingWrap {
    // display: flex;
    // flex-direction: column;
    // margin-top: 32px;
    // // @include px(16px, 16px);
    // @include full-width-with-margin(42px);
    // @include max-width(600px, 42px);
    // height: 100%;
    // @include resp($lg) {
    //     margin-top: 47px;
    // }

    display: flex;
    flex-direction: column;
    @include full-width-with-margin(42px);

    margin-top: 32px;
    @include max-width(600px, 42px);

    @include resp($lg) {
        margin-top: 47px;
    }
}

.MeetingWrap.noMeetingsText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    height: 100%;

    .titleBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .title {
            @include text(32px);
            font-weight: 400;
            color: $dark-gray;
            display: inline-flex;
            text-align: center;
            justify-content: center;
            text-transform: capitalize;
        }

        .subtitle {
            @include text(16px);
            color: $dark-gray;
        }
    }

    .imageContainer {
        width: calc(100% - 56px);
        max-width: 400px;
        max-height: calc(100vh - 500px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// header
.MeetingHeader {

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include px(16px, 16px);

    // @include resp($lg) {
    //     justify-content: center !important;
    //     column-gap: 48px;
    // }
    .Title {
        
        @extend %text-20;
        @include resp($lg) {
            font-size: 24px;
            margin-bottom: 0px;
        }
    }

    .Subtitle {
        @include text(12px);
        color: $light-gray;
        margin-top: 8px;
    }

    .NewMeetingButton {
        background-color: $dark-green;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 12px;
        min-width: 50px;
        width: auto;
        margin-right:12px;
        padding-left:16px;
        padding-right:16px;
        height: 30px;
        transition: background-color 0.3s;
        @include resp-max($lg) {
            display:none;
        }
        &:hover {
            background-color: darken($dark-green, 10%);
        }
    
        .icon {
            font-size: 20px;
            color: white;
        }
    }    
}

.BtnGroup {
    @extend %center;
    box-shadow: $shadow-lg;
    border-radius: 25px;
    height: 30px;
    button {
        @extend %center;
        @include py(0!important, 0!important);
        @extend %text-16;
        width: 90px;
        height: 100%;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        &.active {
            background-color: $dark-green;
            border-radius: 25px;
            color: white;
        }
    }
}

// all meeting list
.MeetingList {
    // margin-top: 24px;
    @include py(20px, 20px);
    @include px(16px, 16px);
    height: 100%;
    // overflow: auto;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    // @extend %scroll-bar;
    
    // @include resp($lg) {
    //     margin-top: 16px;
    //     height: 100%;
    // }
    .Meeting {
        @extend %shadow-card;
        @extend %center;
        @include py(0, 0);
        @include px(14px, 14px);
        border-radius: 50px;
        position: relative;

        cursor: pointer;

        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0px;
        }
        @include resp($lg) {
            margin-bottom: 32px;
        }

        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding-top: 12px;
        padding-bottom: 12px;
        flex-wrap: nowrap;
        gap: 10px;

        .UserNum {
            width: 49px;
            height: 49px;
            color: $button-secondary;
            @extend %center;
            p {
                margin-bottom: 0px;
            }
            .User {
                font-size: 18px;
                margin-left: 2px;
            }
        }
        .Name {
            margin-bottom: 0px;
            width: calc(100% - 49px - 20px);
            @extend %text-18;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-bottom: 2px;
        }

        .ElipsisButton {
            color: $button-secondary;
            position: relative;
            right: 10px;
            transition: color 0.2s;

            &::before {
                content: "";
                position: absolute;
                top: -20px;
                bottom: -20px;
                left: -20px;
                right: -20px;
            }
        }

        .ElipsisButton:hover {
            color: black;
        }

        .dropdownContainer {
            position: absolute;
            right: 10px;
            top: 50px;
            z-index: 500;
        }
    }
}

.deleteOption {
    color: $dark-red !important;
}
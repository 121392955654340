@import '../../scss/partials/links';

.titleBubble {
    display: flex;
    flex-direction: column;

    background-color: white;
    box-shadow: 0px 0px 4px 2px rgba(85, 85, 85, 0.1);
    border-radius: 20px;

    cursor: pointer;

    @include py(8px, 8px);

    .topRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        @include px(15px, 15px);

        .userCount {
            width: 40px;
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 4px;
    
            color: $button-secondary;
    
            position: relative;
            top: 1px;
    
            .userCountText {
                @include text(18px);
                font-weight: 400;
            }
    
            .userIcon {
                font-size: 20px;
            }
        }
    
        .titleText {
            @include text(20px);
            @include px(15px, 15px);
            margin-top: 8px;
            line-height: 1.2;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .titleText.expanded {
            text-overflow: unset;
            overflow: unset;
            white-space: normal;

            overflow-wrap: anywhere;
        }
    
        .expandButton {
            text-align: right;
            color: $button-secondary;
            font-size: 26px;
            width: 40px;
            height: 40px;
            transition: color 0.2s;
        }
    
        .expandButton:hover {
            color: $dark-gray;
        }
    
        .expandButton:active {
            color: black;
        }
    }

    .descriptionText {
        @include text(12px);
        @include mx(20px, 20px);
        margin-top: 8px;
        color: $light-gray;
        font-weight: 400;
        margin-bottom: 8px;
        overflow-wrap: anywhere;
    }

    .descriptionText.italics {
        font-style: italic;
    }

    .peopleList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // column-gap: 20px;
        row-gap: 10px;

        max-height: 100%;
        overflow: scroll;

        @include mx(20px, 20px);
        @include my(4px, 8px);

        width: calc(100% - 40px);
    }

    .person {
        width: calc(25%);
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        white-space: nowrap;
        padding-right: 10px;
    }

    @include resp-max(calc($sm + 30px)) {
        .person {
            width: 33%;
        }
    }

    @include resp-max(500px) {
        .person {
            width: 50%;
        }
    }

    @include resp-max(400px) {
        .person {
            width: auto;
            max-width: 100%;
        }
    }
}
@import "../../../scss/partials/links";


.cus-btn {
    background-color: $dark-purple;
    border-radius: 50px;
    height: 40px;
    width: 100%;
    min-width: 150px;
    color: white;
    @include text(15px);
    &:hover {
        background-color: $dark-purple;
        color: white;
    }
    .icon {
        font-weight: bold;
    }
}

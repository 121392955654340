@import "../../../scss/partials/links";
@import "../../../scss/partials/mixing";
@import "../../../scss/partials/variable";
@import "../../../scss/partials/predefine";

.contentCard {
    width: calc(100% - 30px);
    @include mx(15px, 15px);

    display: flex;
    flex-direction: column;

    @include resp($md) {
        max-width: $md;
        @include mx(calc((100% - $md) / 2), calc((100% - $md) / 2))
    }
}

.formCard {
    border-radius: 25px;
    box-shadow: 0px 0px 5px 1px rgba(77, 77, 77, 0.15);
    background-color: white;
    @include py(16px, 16px);
    @include px(15px, 15px);
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.formInput {
    width: 100%;
    border-radius: 20px;
    background-color: $light-gray-btn;
    padding-left: 19px;
    @include text(12px);
    font-weight: normal;
    outline: none;
    
    border: 0 solid white;
}

.formInput::placeholder {
    @include text(12px);
    opacity: 1;
    color: $light-gray-letter;
    font-weight: normal;
}

.formInput:focus, .formInput:hover {
    border: 2px solid $dark-green;
    padding-left: 17px;
    transition: border 0.2s, padding 0.2s;
}

.formInput.invalid {
    border: 2px solid red;
}

.nameInput {
    height: 40px;
}

.descriptionInput {
    padding: 19px;
    height: 120px;
    resize: none;
}

.descriptionInput:focus, .descriptionInput:hover {
    padding: 17px;
}

.alertContainer {
    margin-top: 16px;
}
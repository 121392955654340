@import "../../../scss/partials/links";

.legendContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-bottom: 16px;

    gap: 24px;

    .legendBarContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 200px;

        gap: 8px;

        .legendLineText {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
    
            @include text(12px);
            color: $text-secondary;
        }
    
        .legendLine {
            height: 12px;
            width: 100%;
            // max-width: 100px;
            // background: linear-gradient(90deg, #F5F5F5, #3EA680);
            background: linear-gradient(270deg, rgb(24.314% 65.098% 50.196%) 0%, rgb(36.946% 70.551% 58.273%) 6.25%, rgb(48.002% 75.324% 65.341%) 12.5%, rgb(57.585% 79.461% 71.468%) 18.75%, rgb(65.803% 83.009% 76.722%) 25%, rgb(72.758% 86.011% 81.169%) 31.25%, rgb(78.558% 88.515% 84.877%) 37.5%, rgb(83.306% 90.565% 87.912%) 43.75%, rgb(87.108% 92.206% 90.343%) 50%, rgb(90.069% 93.484% 92.236%) 56.25%, rgb(92.294% 94.445% 93.659%) 62.5%, rgb(93.888% 95.133% 94.678%) 68.75%, rgb(94.957% 95.594% 95.362%) 75%, rgb(95.605% 95.874% 95.776%) 81.25%, rgb(95.938% 96.018% 95.989%) 87.5%, rgb(96.061% 96.071% 96.067%) 93.75%, rgb(96.078% 96.078% 96.078%) 100% );
            border-radius: 100px;
        }
    }

    .legendItem {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 8px;

        .legendBubble {
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        .legendText {
            @include text(12px);
            color: $text-secondary;
        }
    }
}
@import '../../scss/partials/links';

main.noHeader {
    padding-bottom: 60px;
    overflow: scroll;
}

.topIconBar {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 32px;

    @include mx(15px, 15px);
    width: calc(100% - 30px);
    @include max-width($sm, 30px);

    /* 
    * 'position: relative;' is set to establish a new positioning context for its child elements. 
    * This allows any child element with 'position: absolute;' to position itself relative to this parent, 
    * rather than the viewport or the nearest ancestor with a non-static position. 
    * Do not remove this line, as it ensures that the menuContainer dropdown is positioned relative to the
    * topIconBar, rather than the viewport.
    * Why does it work like that? No idea. But it does.
    * To be clear, this is not set because the position of the topIconBar is being offset in any way.
    * topIconBar retains it's default position as if it were set to static.
    */
    position: relative;

    .iconButton {
        font-size: 24px;
        color: $light-gray;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        transition: color 0.2s;

        cursor: pointer;
    }

    .iconButton:hover {
        color: lighten($dark-gray, 10%);
    }

    .iconButton:active {
        color: black;
    }

    .backButton svg {
        scale: 1.2;
    }

    .menuContainer {
        width: 120px;
        position: absolute;
        // right: 84px;
        // bottom: 10px;
        right: 40px;
        top: 30px;
        z-index: 100;
        color: black;
        transition: none;

        cursor: pointer;
    }

    svg {
        position: static;
    }

    .deleteOption {
        color: $dark-red;
    }

    .spacer {
        flex-grow: 1;
    }
}

.titleBubbleContainer {
    margin-top: 12px;
    @include mx(15px, 15px);
    width: calc(100% - 30px);

    @include max-width($sm, 30px);
}

.clickHelpPrompt {
    @include text(14px);
    color: $light-gray;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 16px;
}

.availabilityCardContainer {
    width: calc(100% - 84px);
    @include max-width(400px, 84px);
    @include mx(42px, 42px);
    position: relative;
}

.availabilityPopupContainer {
    width: calc(100% - 30px);
    @include max-width($sm, 30px);
    @include mx(15px, 15px);
    max-height: 50%;

    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);

    box-shadow: 0px 0px 2px 1px rgba(85, 85, 85, 0.1);
    border-radius: 20px;

    position: absolute;
    bottom: 15px;
    z-index: 100;

    @include py(12px, 14px);
    @include px(18px, 18px);

    display: flex;
    flex-direction: column;
    gap: 10px;

    .topRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .timeDateBar {
            @include text(12px);
            background-color: $cream;
            box-shadow: 0px 0px 2px 1px rgba(85, 85, 85, 0.12);
            border-radius: 20px;
            @include py(4px, 4px);
            @include px(8px, 8px);
        }

        .closeButton {
            font-size: 20px;
            color: $light-gray;
            width: 30px;
            height: 30px;
            text-align: right;
            pointer-events: all;

            cursor: pointer;

            transition: color 0.2s;
        }

        .closeButton:hover {
            color: lighten($dark-gray, 10%);
        }

        .closeButton:active {
            color: black;
        }

        .closeButton svg {
            // corrects for the svg being slightly off center
            position: relative;
            bottom: 2px;
        }
    }

    .scrollView {
        overflow: scroll;
    }

    .scrollView.scrollable {
        overflow: scroll;
        pointer-events: all;
    }

    /** 
    * NOTE: If you change the height of a people list, please update the variable
    * maxHeight in the recheckAvailablityPopupState function in MeetingOverview.js.
    * That variable is the height in pixels the people list is allowed to be before
    * being compressed into a "see more" button. It DOES NOT AUTOMATICALLY UPDATE
    * AND MUST BE UPDATED MANUALLY.
    **/
    .peopleList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // column-gap: 20px;
        row-gap: 10px;

        margin-top: 14px;

        @include mx(10px, 10px);

        width: calc(100% - 20px);

        &.capped {
            max-height: 71px;
            overflow: hidden;
        }
    }

    .peopleListTitle {
        @include text(16px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        @include mx(10px, 10px);

        svg {
            font-size: 20px;
        }
    }

    .peopleSection:not(:last-child) {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .person {
        width: calc(25%);
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        white-space: nowrap;
        padding-right: 10px;
    }

    @include resp-max(calc($sm + 30px)) {
        .person {
            width: 33%;
        }
    }

    @include resp-max(500px) {
        .person {
            width: 50%;
        }
    }

    @include resp-max(400px) {
        .person {
            width: auto;
            max-width: 100%;
        }
    }

    .seeMoreButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $light-gray;
        @include text(12px);
        margin-top: 16px;

        transition: color 0.2s;

        svg {
            font-size: 20px;
        }

        &:hover {
            color: lighten($dark-gray, 10%);
        }

        &:active {
            color: $dark-gray;
        }
    }
}

.maxWidthContainer {
    width: calc(100% - 30px);
    @include max-width($sm, 30px);
    @include mx(15px, 15px);
    position: sticky;
    bottom: -42px;
}

.premiumFeaturesPopupContainer {
    width: calc(100% - 30px);
    @include max-width($sm, 30px);
    @include mx(15px, 15px);
    position: sticky;
    bottom: -42px;
    backdrop-filter: blur(10px);
}

.premiumFeaturesContainer {
    width: calc(100% - 30px);
    @include max-width($sm, 30px);
    @include mx(15px, 15px);
    position: sticky;
    bottom: -42px;
}

@media (max-width: calc($sm + 30px)) {
    .premiumFeaturesContainer {
        width: 100%;
    }
}
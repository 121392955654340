@import '../../scss/partials/links';

.formWrap {
  margin-top: 32px;

  padding-top: 24px;
  padding-bottom: 24px;
  @include mx(15px, 15px);
  width: calc(100% - 30px);
  @include max-width($sm, 30px);

  @include px(15px, 15px);

  border-radius: 25px;
  background: var(--main-white, #FFF);
  box-shadow: 0px 0px 5px 1px rgba(77, 77, 77, 0.15);
  
  .logoWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    
    // @include mx(auto, auto);
    // margin-bottom: 30px;

    @include text(16px);

    .Logo {
        height: 18px;
        display: inline-block;
    }
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;

  margin-top: 24px;

  .lineText {
    @include my(8px, 8px);
  }
  
  .loginForm {
    width: 100%;

    .bottomRow {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .forgotPasswordText {
        cursor: pointer;
        color: $light-gray;
        transition: color 0.2s, text-decoration 0.2s;
        margin-left: 8px;
      }
  
      .forgotPasswordText:hover {
        text-decoration: underline;
        color: $dark-purple;
      }
    }
  }
}

.signUpTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  @include text(12px);
  color: $light-gray;
  margin-top: 16px;

  .signUpButton {
    @include text(12px);
    color: darken($light-gray, 20%);
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.2s;
  }

  .signUpButton:hover {
    color: $dark-purple;
  }

  .signUpButton:active {
    color: darken($dark-purple, 10%);
  }
}

.loginButton {
  @include py(10px, 10px);
  padding-left: 20px;
  padding-right: 16px;
  border-radius: 100px;

  outline: none;
  border: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  vertical-align: center;

  background-color: $dark-purple;
  color: white;
  box-shadow: $shadow-lg;

  transition: background-color 0.2s;
}

.loginButton:hover {
  background-color: darken($dark-purple, 5%);
}

.loginButton:active {
  background-color: darken($dark-purple, 10%);
}

.recipientWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .recipientTitle {
    @include text(16px);
    color: $dark-gray;
  }

  .recipientSubtitle {
    @include text(12px);
    color: $light-gray;
  }
}

.popupContainer {

  width: calc(100% - 30px);
  @include max-width($sm, 30px);
  @include mx(15px, 15px);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 20px;

  .popup {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;

    border-radius: 10px;
    border: 2px solid #DBB75B;
    background-color: #F1EBDC;
    padding: 10px;

    .popupText {
      @include text(14px);
      color: $dark-gray;
    }
  }
}
@import "../../../../scss/partials/links";

.Calender {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    // bottom shadow
    &::after {
        content: "";
        width: 100%;
        height: 10vh;
        // @include resp ($lg) {
        //     height: 10vh;
        // }
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        background: rgb(255, 255, 255);
        // background: linear-gradient(
        //                 0deg,
        //                 rgba(255, 255, 255, 1) 0%,
        //                 rgba(255, 255, 255, 1) 50%,
        //                 rgba(255, 255, 255, 0.5019257703081232) 100%
        // );
        background: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
        );
        pointer-events: none;
    }
    // @include resp($lg) {
    //     padding-top: 16px !important;
    // }
}

.dayList {
    padding: 0;
    width: 100%;
    @include mx(auto, auto);
    @include px(0px!important, 0px!important);
    @include py(6px!important, 6px!important);
    align-items: center;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 16px;
    @include resp($lg) {
        // max-width: $md !important;
        margin-bottom: 20px;
    }
    .day {
        @include text(12px);
        color: $dark-gray;
        text-transform: capitalize;
        @include px(0px, 0px);
        text-align: center;
        @include resp($lg) {
            font-size: 16px;
        }
    }
}

.calenderContainer {
    // max-height: 150px;
    // max-height: 130px;
    height: 100%;
    overflow: scroll;
    // overflow-y: scroll;
    width: 100%;
    padding-bottom: 15vh;
    // max-width: 290px;
    @include mx(auto, auto);
    &::-webkit-scrollbar {
        display: none;
    }
    // @include resp($lg) {
    //     // max-width: $md;
    //     max-height: 210px;
    // }
    .Month {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    // title
    .MonthName {
        @include text(16px);
        color: $dark-gray;
        text-align: center;
    }
    // date wrapper
    // .dateWrap {
    // }

    // week row -------------
    .weekRow {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    // date -----------------
    %centerRound {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;
    }
    .Date {
        @extend %centerRound;
        padding: 3px;
        cursor: pointer;
        height: 22px;
        width: 75%;
        border-radius: 20px;
        margin-bottom: 0;
        @include text(12px);
        font-weight: 500;
        @include resp($lg) {
            font-size: 16px;
            height: 28px;
        }
        transition: background-color 0.2s, color 0.2s;
        &.start,
        &.end {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 5%;
                top: 13%;
                width: 89%;
                height: 75%;
                background-color: $dark-green;
                z-index: -1;
                border-radius: 20px;
            }
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $light-green;
                z-index: -5;
                border-radius: 20px;
            }
        }
        &.start {
            &::after {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
        }

        &.end {
            &::after {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }

        // selected

    }

    @include resp($lg) {
        .Date:hover:not(.Active) {
            background-color: lighten($light-green, 5%);
        }
    }

    .DateGray {
        @extend %centerRound;
        padding: 3px;
        cursor: pointer;
        height: 22px;
        width: 100%;
        border-radius: 20px;
        margin-bottom: 0;
        @include text(12px);
        @include resp($lg) {
            font-size: 16px;
            height: 28px;
        }

        color:rgba(125, 125, 125, 0.5);
        &.start,
        &.end {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 5%;
                top: 13%;
                width: 89%;
                height: 75%;
                background-color: $dark-green;
                z-index: -1;
                border-radius: 20px;
            }
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $light-green;
                z-index: -5;
                border-radius: 20px;
            }
        }
        &.start {
            &::after {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }
        }

        &.end {
            &::after {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }

        // selected



    }
}

.dayContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Controls active date */
.Date {

    &.Active {
        color: white;
        position: relative;
        background-color: $dark-green;
        width: 75%;
        // background-color: $dark-green;
        // &::after {
        //     content: "";
        //     width: 50%;
        //     height: 100%;
        //     background-color: $dark-green;
        //     z-index: -5;
        //     border-radius: 20px;
        // }
        &.invalid {
            &::before {
                background-color: #a63e3e;
            }
            &::after {
                background-color: #d3a9a9;
            }
        }
    }
}

// .startWeek {
//     name: "start-week";
// }

@import '../../../scss/partials/links';

.popupContainer {
    
    // styling
    background-color: white;
    box-shadow: 0px -1px 4px 2px rgba(85, 85, 85, 0.20);
    
    // sizing
    width: 135px;
    @include py(74px, 0px);
    @include px(24px, 24px);

    @include resp($sm) {
        padding-top:80px;
    }
    height: 100vh;

    // position
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 1000;
    @include text(20px);
    font-weight: 600;
    color: #464646;

    flex-direction:column;
    text-align:left;

    display: flex;

    &.noPadding {
        padding: 0;
    }
}
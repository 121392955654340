@import "../../../scss/partials/links";

.button {
    @include text(16px);
    @include px(10px, 10px);
    @include py(4px, 4px);
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 100px;

    transition: background-color 0.2s;
    cursor: pointer;
    user-select: none;
}

.gray {
    color: #636363;
    background-color: $light-gray-btn;

    &:hover {
        background-color: darken($light-gray-btn, 5%);
    }

    &:active {
        background-color: darken($light-gray-btn, 10%);
    }
}

.green {
    background-color: $dark-green;
    color: white;

    &:hover {
        background-color: darken($dark-green, 5%);
    }

    &:active {
        background-color: darken($dark-green, 10%);
    }
}

.purple {
    background-color: $dark-purple;
    color: white;

    &:hover {
        background-color: darken($dark-purple, 5%);
    }

    &:active {
        background-color: darken($dark-purple, 10%);
    }
}
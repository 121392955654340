@import '../../scss/partials/links';

.customMain {
    position: relative;
    z-index: 2;
    bottom: 0;
    overflow: auto;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 70px 10px rgba(255, 255, 255, 1);
    border-radius: 50px;

    // .secTitle, .slogan, .logoWrap {
    //     // add a white fadeout dropdshadow that is elliptical
    //     box-shadow: 0px 0px 70px 10px rgba(255, 255, 255, 0.7);
    //     background-color: rgba(255, 255, 255, 0.7);
    // }

    .secTitle {
        text-align: center;
        @include text(16px);
        color: $dark-gray;
        margin-bottom: 24px;
        @include resp($lg) {
            font-size: 20px;
        }
    }
    .slogan {
        @extend %text-16;
        text-align: center;
        // margin-bottom: 32px;
        span {
            color: $dark-green;
        }
    }

    .IntroCellImg {
        width: 150px;
        @include mx(auto, auto);
        margin-bottom: 32px;
        img {
            width: 100%;
        }
    }
}

.logoWrap {
    width: 190px;
    @include mx(auto, auto);
    margin-bottom: 30px;
    img {
        width: 100%;
    }
}

.bottomHalfContainer {
    margin-top:20px;
    @include resp-height($sm) {
        margin-top: 190px;
    }

    .buttonContainer {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .buttonWrap {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            justify-content: center;

            width: 100%;

            .signUpButton, .loginButton, .googleButton {
                @include text(16px);

                @include py(12px, 12px);
                
                width: calc(100% - 40px);
                @include mx(20px, 20px);

                @include max-width($sm - 320px, 40px);

                outline: none;
                border: none;
                
                border-radius: 50px;

                box-shadow: 0px 2px 5px 1px rgba(77, 77, 77, 0.15);
                transition: background-color 0.2s;
            }

            .signUpButton {            
                background: $dark-purple;
                color: white;
            }

            .signUpButton:hover {
                background-color: darken($dark-purple, 5%);
            }

            .signUpButton:active {
                background-color: darken($dark-purple, 10%);
            }

            .loginButton {
                outline-style: solid;
                outline-width: 2px;
                outline-color: $dark-purple;
                color: $dark-purple;
                background: white;
            }

            .loginButton:hover {
                background-color: darken(white, 5%);
            }

            .loginButton:active {
                background-color: darken(white, 10%);
            }

            .googleButton {
                background: $dark-purple;
                box-shadow: 0px 0px 4px 2px rgba(55, 55, 55, 0.15);
                color: white;
            }
        }
    }

    .videoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        
        margin-top: 50px;
        @include resp-height($sm) {
            margin-top: 95px;
        }

        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 70px 10px rgba(255, 255, 255, 1);
        border-radius: 50px;

        .caption {
            @include text(16px);
            @extend %text-16;
            text-align: center;
            img {
                width: 100px;
            }
        }

        .videoWrapper {
            position: relative;
            width: 100%;
            
            iframe {
                @include resp-max($sm) {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
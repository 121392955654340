@import "../../../scss/partials/links";

.timeColumn {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
    justify-content: flex-start;

    .skipRow {
        // THIS MAY CHANGE IN THE FUTURE
        height: 0px;
    }

    .time {
        display: inline-flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        
        @include text(10px);
        height: 18px;
        color: $dark-gray;
    }

    .lineSpacer {
        width: 100%;
        height: 2px;
    }

    .dot {
        height: 10px;

        &::before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $light-gray;
        }
    }

    .inlineContainer {
        position: relative;
        display: inline-flex;

        .timeZone {
            @include text(8px);
            color: $text-secondary;

            position: absolute;
            right: 30px;
            top: 1px;
        }
    }
}
// google fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// scss modules
@import "partials/variable";
@import "partials/mixing";
@import "partials/predefine";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $global_font;

    // hide scroll bars
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

a {
    text-decoration: none;
}

main {
    // for smallest screens, add margins to bypass the header and footer
    padding-top: 56px;
    padding-bottom: 60px;

    height: 100vh;
    height: 100dvh;
    overflow: hidden;

    // border: 15px solid green;
    @include resp($sm) {
        padding-top: 68px;
        padding-bottom: 70px;
        // border: 15px solid red;
    }
    // @include resp ($md) {
    //     padding-top: 88px;
    // }
    @include resp($lg) {
        padding-top: 76px;
        padding-bottom: 0;
        height: 100vh;
        // border: 15px solid blue;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}

main.no-header {
    padding-top: 0;
}

// common typography and utility class -----------

.text-16px {
    @include text(16px);
}
.text-12px {
    @include text(12px);
}
.title-20px {
    @include text(20px);
    color: $dark-gray;
    text-transform: capitalize;
}
.my-20px {
    @include my(20px, 20px);
}

%btn {
    border: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.form-change-btn {
    @extend %btn;
    background-color: $light-green;
    color: $dark-gray;
    @extend .text-16px;
    @include px(22px, 22px);
}

// all rounded row and button styles
.round-element {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.round-element-shadow {
    @extend .round-element;
    box-shadow: $shadow-sm;
}

// cards

// common custom class
// body {
//     height: 100vh;
// }

#root {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    // overflow: hidden;
    @include resp($lg) {
        // overflow-x: hidden;
    }
}

#portal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 500;
}

.shadow-card {
    width: 100%;
    box-shadow: $shadow-sm;
    border-radius: 25px;
    padding: 16px;
    @include resp($lg) {
        box-shadow: $shadow-lg;
        padding: 32px;
    }
}

.max-container {
    max-width: 1440px !important;
    margin-left: auto;
    margin-right: auto;
}

// global form style ----------
.form-wrapper {
    max-width: 360px;
    @include mx(auto, auto);
}
.form-container {
    @include px(15px, 15px);
    @include py(20px, 20px);
}
legend {
    @include text(16px);
    color: $dark-gray;
    text-align: center;
    margin-bottom: 22px;
    img {
        width: 90px;
        margin-left: 5px;
    }
}

// form style
.form-group {
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0px;
    }
}

.form-label {
    @include text(14px);
    color: $dark-gray;
    @include resp($lg) {
        @include text(20px);
        margin-bottom: 16px;
    }
}

.form-control {
    @include px(20px, 20px);
    color: $dark-gray;
    @include text(12px);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: $light-gray-btn;
    border-color: $light-gray-btn;
    &:focus {
        border: none;
        box-shadow: none;
        background-color: $light-gray-btn;
    }
    &.invalid {
        border-color: red;
    }
}

// section styles -----------------------
.main-section {
    padding: 15px;
    padding-bottom: 32px;
    @include resp($sm) {
        @include py(32px, 32px);
    }
    // @include px(15px, 15px);
    height: calc(100vh - (88px + 135px));
    overflow: hidden;
    overflow-y: auto;
    @include resp($lg) {
        @include py(48px, 48px);
    }
}

.signup-btn {
    @extend %text-16;
    color: white;
    text-transform: capitalize;
    @extend %cus-btn;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-green;
    transition: all 0.25s;
    &:hover {
        background-color: $dark-green;
    }
}

.signup-btn--hover {
    @extend .signup-btn;
    background-color: transparent;
    color: $dark-gray;
    background-color: transparent;
}

.alert {
    @include text(12px);
    color: $dark-red;
    text-align: center;
    margin-bottom: 0px !important;
    padding: 0px !important;
    padding-top: 12px !important;
}

// @media only screen and (max-width: $lg) {
//     input {
//         font-size: 16px !important;
//     }
// }

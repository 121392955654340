@import '../../../scss/partials/links';

.wrapper {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    width: 100%;

    padding-bottom: 8px;
    
    .title {
        @include text(16px);
    }

    .optionsList {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;

        .option {

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            cursor: pointer;

            width: calc(100% + 24px);
            transition: background-color 0.2s;

            padding: 4px 12px;
            border-radius: 50px;

            .iconContainer {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 40px;
                height: 40px;

                .icon {
                    width: 32px;
                    height: 32px;
                }
            }

            .text {
                width: 100%;
                @include text(16px);
            }

            .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                transition: color 0.2s;
            }

            &:hover {
                background-color: $light-gray-btn;

                .arrow {
                    color: $dark-gray;
                }
            }

            &:active {
                background-color: darken($light-gray-btn, 10%);
            }
        }
    }

    .cancelButton {
        @include text(12px);
        font-weight: 400;
        color: $light-gray;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
}
@import "../../scss/partials/links";

.availabilityCard {
    padding-top: 16px;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        height: 100%;

        .grid {
            display: flex;
            flex-grow: 1;

            &.edit {
                touch-action: none;
                cursor: pointer;
            }
        }
    }
}

.warningBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    margin-top: 30px;
}
@import '../../../scss/partials/links';

.popupContainer {
    width: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 25px;
    box-shadow: 0px 1px 4px 2px rgba(85, 85, 85, 0.20);
    @include px(30px, 30px);
    @include py(30px, 20px);

    @include max-width($sm, 30px);

    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
        @include text(20px);
    }

    .message {
        @include text(16px);
        font-weight: 400;
        color: $light-gray;
    }

    .buttonRow {
        display: flex;
        flex-direction: row;
        margin-top: 6px;
        gap: 20px;

        .button {
            @include py(14px, 14px);
            @include px(20px, 20px);
            border-radius: 50px;
            @include text(16px);
            font-weight: 400px;
            flex-grow: 1;
            text-align: center;
            transition: background-color 0.2s, color 0.2s;

            cursor: pointer;
        }

        .button.withIcon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .cancelButton {
            background-color: $light-gray;
            color: white;
        }

        .cancelButton:hover {
            background-color: darken($light-gray, 10%);
        }

        .cancelButton:active {
            background-color: darken($light-gray, 20%);
        }

        .submitButton {
            background-color: $dark-purple;
            color: white;
        }

        .submitButton:hover {
            background-color: darken($dark-purple, 10%);
        }

        .submitButton:active {
            background-color: darken($dark-purple, 20%);
        }
    }
}
@import "../../scss/partials/variable";
@import "../../scss/partials/mixing";

.mobNavbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // column-gap: 10px;
    column-gap: 30px;
    height: 60px;
    box-shadow: $shadow-sm;
    @include resp ($sm) {
        height: 70px;
        column-gap: 40px;
    }
    @include resp($lg) {
        display: none;
    }
    .navLink {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 35px;
        border-radius: 50px;
        border: none;
        color: $dark-gray;
        @include resp ($sm) {
            width: 80px;
            height: 48px;
        }
        .NavIcon {
            font-size: 20px;
            @include resp ($sm) {
                font-size: 24px;
            }
        }
        &.active {
            background-color: $dark-green;
            color: white;
        }
    }
}

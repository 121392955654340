@import "../../../scss/partials/links";

.bubbleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    width: 100%;

    cursor: pointer;

    .bubble {
        height: 18px;
        width: 100%;
        border-radius: 20px;

        transition: background-color 0.2s, box-shadow 0.2s, scale 0.2s;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        user-select: none;
        overflow: hidden;

        &.disabled {
            background-color: #E3E3E3;
            width: 50%;
            height: 4px;
            margin: 7px 0;
        }

        &.active {
            background-color: $dark-purple !important;
            box-shadow: 0px 0px 0px 3px lighten($dark-purple, 20%);
        }

        &.dragging {
            background-color: #46347D !important;
            scale: 0.9;
        }

        &.selected {
            background-color: $dark-purple !important;
        }
    }

    .line {
        width: calc(100% + 10px);
        height: 2px;
        background-color: #E3E3E3;
        margin-top: 5px;
    }

    &.skipRow {
        height: auto;
    }

    /** Expanded touch target, there should be no gaps */
    &::before {
        content: "";
        position: absolute;
        top:-2.5px;
        bottom:-2.5px; 
        left:-5px;
        right:-5px;
        // background-color: blue; // for testing, should completely blue out the grid when enabled
    }
}
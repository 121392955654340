@import '../../scss/partials/links';

.contentWrap {
  @extend %shadow-card;
  width: 360px;
  padding: 32px 55px;
  background-color: white;
  @include resp ($lg) {
    width: 500px;
    @include px(125px, 125px);
  }
}

.formWrap {
  @extend .contentWrap;
  padding: 24px 15px;
  @include resp ($lg) {
    @include px(85px, 85px);
  }
}

.Title {
  @extend %text-20;
  margin-bottom: 24px;
}

.btnWrap {
  display: flex;
  flex-flow: column wrap;
  gap: 16px;
}



.topBar {
  @extend %text-16;
  text-align: center;
  .logo {
    width: 80px;
    display: inline-block;
    margin-left: 3px;
    img {
      width: 100%;
    }
  }
}

.formContent {
  margin-top: 24px;
}

.btn {
  min-height: 40px;
  @extend %cus-btn;
  @extend %center;
  @include px(20px, 20px);
  font-size: 14px;
  width: initial;
  min-width: initial;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
}

.or {
  @extend %text-16;
  text-align: center;
  @include my(24px, 24px);
  text-transform: capitalize;
}

.greenBtn {
  @extend .btn;
  background-color: $dark-green;
  display: inline-flex;
  &:hover {
    background-color: $dark-green;
  }
}

.googleBtn {
  @include my(24px, 0px);
}

.forgotPass {
  @extend %text-10;
  text-transform: capitalize;
  color: $light-gray-letter;
}

.orSm {
  @extend .or;
  font-size: 14px;
  @include my(16px, 16px);
}
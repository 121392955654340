@import "../../scss/partials/mixing";
@import "../../scss/partials/variable";

.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    z-index: 100;
}

.lgFooter {
    display: none;
    @include resp($lg) {
        display: flex;
        justify-content: center;
        align-items: center;
        @include py(35px, 35px);
    }

    .link {
        @include text(14px);
        text-decoration: none;
        color: $light-gray;
        text-transform: capitalize;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 32px;
        &:last-child {
            margin-right: 0px;
        }
    }
}

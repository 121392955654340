@import "../../../scss/partials/links";
@import "../../../scss/partials/mixing";
@import "../../../scss/partials/variable";
@import "../../../scss/partials/predefine";

.progressBarContainer {
    /* box sizing */
    width: calc(100% - 30px);
    height: 60px;

    /* box styling */
    box-shadow: 0px 0px 5px 1px rgba(77, 77, 77, 0.15);
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);

    /* box positioning (footer is 60px) */
    position: absolute;
    bottom: 74px;
    @include mx(15px, 15px);

    @include resp($sm) {
        bottom: 84px;
    }

    @include resp($md + 30px) {
        max-width: $md;
        @include mx(calc((100% - $md) / 2), calc((100% - $md) / 2))
    }

    @include resp($lg) {
        bottom: 24px;
    }
}

.columnContainer {
    @include mx(45px, 45px);
    @include my(16px, 16px);
    // background-color: blue;
    width: calc(100% - 90px);
    // height: calc(100% - 32px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
}

.stageNameRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.stageName {
    width: calc(100% / 3);
    margin-top: -3px;
    text-align: center;
    @include text(16px);
    font-style: normal;
    color: $light-gray-letter;
}

.stageName.active {
    color: $dark-green;
}

.progressBarRow {
    width: 100%;
    background-color: $light-gray-btn;
    border-radius: 10px;
    height: 8px;
}

.progressBar {
    background-color: $dark-green;
    height: 8px;
    border-radius: 10px;
    position: relative;
    top: -8px;
}

.progressBar.position0 {
    width: calc(16.666666% + 4px);
}

.progressBar.position1 {
    width: calc(50% + 4px);
}

.progressBar.position2 {
    width: calc(83.333333% + 4px);
}

.progressBar.position3 {
    width: 100%;
}

.dotsRow {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dotContainer {
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $light-gray-letter;
}

.leftArrow {
    position: relative;
    bottom: 48px;
    left: 8px;
    font-size: 30px;
    color: $light-gray-letter;
    transition: color 0.2s;

    cursor: pointer;
}

.leftArrow:hover {
    color: $dark-gray;
}
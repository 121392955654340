@import '../../../scss/partials/links';

.inputWrapper {

    // internal positioning
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 16px;
    position: relative;

    // styling
    transition: background-color 0.2s;
    border-radius: 40px;
    background-color: #F5F5F5;
    color: black;
    @include text(12px);
    transition: color 0.2s;

    // sizing
    height: 40px;

    .downArrow {
        margin-left: -4px;
        font-size: 22px;
    }

    &.active {
        background-color: #EDF8F4;
        color: #3EA680;
    }

    &.disabled {

        color: $light-gray;
    }

    &:not(.disabled):not(.active):hover {
        background-color: #EFEFEF;
    }

    // this is the actual drop down part
    .dropDownWrapper {
        
        // external positioning
        position: absolute;
        top: 40px;
        right: 0;

        // internal positioning
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;

        // styling
        background-color: white;
        border-radius: 15px;
        box-shadow: 0px 1px 4px 2px rgba(85, 85, 85, 0.20);

        .searchBar {

            // sizing
            width: 100%;
            height: 40px;

            // internal positioning
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            @include px(8px, 8px);

            // styling
            border-radius: 8px;
            border: 1px solid var(--text-secondary, #A1A1A1);
            background: var(--main-text-box-fill, #F5F5F5);
            transition: border-color 0.2s;

            svg {
                font-size: 16px;
                margin-right: 8px;
                color: black;
            }

            .searchInput {
                height: 100%;
                appearance: none;
                outline: none;
                border: none;
                background: none;
            }

            &:focus-within  {
                border: 1px solid var(--main-green, #3EA680);
            }
        }

        .optionsWrapper {

            position: relative;
            width: 100%;

            .optionsContainer {

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                height: 140px;
                overflow: scroll;
                width: 100%;
                padding-top: 8px;
                padding-bottom: 8px;
                // margin-top: 8px;
                // margin-bottom: 8px;

                .option {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    flex-shrink: 0;

                    @include px(16px, 16px);
                    @include py(8px, 8px);
                    
                    color: $dark-gray;
                    @include text(12px);
                    font-weight: 500;

                    transition: color 0.2s, background-color 0.2s;
                    border-radius: 50px;

                    &.active {
                        background-color: #EDF8F4;
                        color: #3EA680;
                    }

                    &:hover {
                        color: black;
                        background-color: #C6EBDE;
                    }
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                width: 100%;
                height: 8px;
                background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 8px;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            }
        }
    }
}
.footer {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: white;
}

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.footerLogo {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.footerLogo img {
  width: 300px;
}

.footerCategory {
  margin: 10px;
}

.footerCategory h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.footerCategory ul {
  list-style: none;
  padding: 0;
}

.footerCategory ul li {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.footerCategory a {
  text-decoration: none;
  color: #333333;
}

.socialMedia {
  bottom: 20px;
  right: 20px;
  display: flex;
}

.socialMedia a {
  margin-left: 10px;
  text-decoration: none;
}

.legalNotice {
  margin-top: 10px;
}

.legalNotice a {
  font-size: 0.8em;
  text-decoration: none;
  color: #7d7d7d;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .footerLogo {
    display: none;
  }
}
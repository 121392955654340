// colors
$dark-purple: #8669df;
$light-purple: #d0bde1;
$dark-green: #3ea680;
$light-green: #a9d3c4;
$dark-gray: #4d4d4d;
$light-gray: #a2a2a2;
$light-gray-btn: #f5f5f5;
$light-gray-letter: #a2a2a2;
$dark-red: #ea483a;
$dark-black: #777777;
$button-secondary: #7d7d7d;
$cream: #f7f7f7;

$text-primary: #464646;
$text-secondary: #a1a1a1;

$colorList: (
        dark-purple: $dark-purple,
        dark-green: $dark-green,
        light-green: $light-green,
        dark-gray: $dark-gray,
        light-gray: $light-gray,
        light-gray-btn: $light-gray-btn,
        dark-red: $dark-red,
);
@each $name, $color in $colorList {
  .bg-#{$name} {
    background-color: $color !important;
  }
  .text-#{$name} {
    color: $color !important;
  }
}

// shadow
$shadow-sm: 0px 0.5px 5px 1px rgba(76, 76, 76, 0.15);
$shadow-lg: 0px 0.5px 5px 1px rgba(77, 77, 77, 0.15);

// google fonts
$global_font: "Inter", sans-serif;

// responsive breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$mxl: 1800px;

// responsive list

@import "../../scss/partials/links";

.pageWrap {
    // internal positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // external positioning
    margin-top: 24px;
    @include px(15px, 15px);
    @include max-width($sm, 30px);

    &.gone {
        display: none;
    }

    .containerWrap {
        // internal positioning
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        // sizing
        width: 100%;
        
        // internal positioning
        padding: 16px;
        gap: 16px;

        // styling
        background-color: white;
        box-shadow: 0px 0px 5px 1px rgba(77, 77, 77, 0.15);
        border-radius: 25px;
    
        .inputWrap {

            display: flex;
            flex-direction: column;
            gap: 8px;
            @include px(4px, 4px);
            width: 100%;
            
            .inputLabel {
                @include text(14px);
                @include px(7px, 7px);
            }
    
            .input {
                @include text(12px);
                -webkit-appearance: none;
                appearance: none;
                @include px(20px, 20px);
                @include py(10px, 10px);
                border: none;
                border-radius: 50px;
                background-color: #F5F5F5;

                transition: box-shadow 0.2s;

                &:disabled {
                    filter: saturate(20%) brightness(90%);
                }

                &:hover:not(:disabled) {
                    box-shadow: 0px 0px 5px 1px rgba(77, 77, 77, 0.15);
                }
            }
        }
    }

    .alertWrap {
        margin-top: 16px;
        width: 100%;
    }

    .saveButton {
        margin-top: 24px;
        display: inline-flex;
        padding: 14px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 100px;
        background: $dark-purple;
        color: white;
        appearance: none;
        border: none;
        transition: background-color 0.2s;

        @include text(16px);

        &:hover {
            background-color: darken($dark-purple, 5%);
        }

        &:active {
            background-color: darken($dark-purple, 10%);
        }
    }
}
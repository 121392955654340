@import '../../../scss/partials/links';

.deleteButton {
    background-color: $dark-red;
    color: white;
}

.deleteButton:hover {
    background-color: darken($dark-red, 10%);
}

.deleteButton:active {
    background-color: darken($dark-red, 20%);
}
@import '../scss/partials/links';

.wrap {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);

  &.noBlur {
    backdrop-filter: none;
  }
}

.contentWrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @extend %center;
}
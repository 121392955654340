@import "../../scss/partials/links";

.deleteAccountPopup {

    // sizing
    width: calc(100% - 30px);
    @include mx(15px, 15px);
    @include max-width($sm, 30px);

    // internal positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    padding: 24px;

    // styling
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 0px 2px 1px rgba(85, 85, 85, 0.15);

    .topRow {

        // sizing
        width: 100%;
        height: 24px;

        // internal positioning
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;

        .icon {
            color: #E1261A;
            @include text(20px);
        }

        .title {
            @include text(20px);
        }

        .spacer {
            flex-grow: 1;
        }

        .backButton {
            color: $dark-gray;
            @include text(20px);
            transition: color 0.2s;

            &:hover {
                color: lighten($dark-gray, 20%);
            }

            &:active {
                color: $light-gray;
            }
        }
    }

    .subtitle {
        // positioning
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        // sizing
        width: 100%;

        // text styling
        @include text(16px);
        font-weight: 400;
        color: $light-gray;
    }

    .textArea {
        // sizing
        height: 120px;
        width: 100%;

        // internal positioning
        padding: 16px;

        overflow: scroll;

        // styling
        @include text(12px);
        appearance: none;
        border: none;
        background-color: #F5F5F5;
        border-radius: 20px;
        resize: none;
        transition: background-color 0.2s, border-color 0.2s;
        outline: none;
        border: 2px solid transparent;

        &:hover:not(:focus) {
            background-color: #EFEFEF;
        }

        &:focus {
            border: 2px solid lighten($dark-gray, 20%);
        }
    }

    .buttonRow {

        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;

        width: 100%;

        .button {
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            padding: 14px 20px;
            border-radius: 100px;
            @include text(16px);

            cursor: pointer;

            width: 100%;

            &.cancelButton {
                background-color: $light-gray;
                transition: background-color 0.2s;
                color: white;

                &:hover {
                    background: #7D7D7D;
                }

                &:active {
                    background: $dark-gray;
                }
            }
    
            &.deleteButton {
                background-color: #E1261A;
                transition: background-color 0.2s;
                color: white;
                text-align: center;

                &:hover {
                    background-color: darken(#E1261A, 5%);
                }

                &:active {
                    background-color: darken(#E1261A, 20%);
                }

                .loadingSpinner {
                    animation-name: spin;
                    animation-duration: 4000ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;

                    @keyframes spin { 
                        from { 
                            transform: rotate(0deg); 
                        } to { 
                            transform: rotate(360deg); 
                        }
                    }
                }
            }
        }

    }
}
@import "../../../scss/partials/links";

.table {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 32px;
    padding-top: 32px;
    border-radius: 24px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include resp-max($lg) {
        margin: 20px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 20px 10px 20px;
        max-width: 450px;
    }
    @include resp($lg) {
        max-width: 700px;
    }

    .header {
        @include text(24px);
        @include resp-max($lg) {
            @include text(20px);
        }
        font-weight: 600;
        padding-bottom: 10px;
        text-align: center;
        color: #464646;

        &.blank {
            visibility: hidden;
        }
    }

    .body {
        @include text(24px);
        @include resp-max($lg) {
            @include text(16px);
        }
        font-weight: 600;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 2px solid #DFDFDF;

        .checkmark {
            color: #464646;
            stroke-width: 4px;
            height: 32px;
            width: 32px;
            @include resp-max($lg) {
                stroke-width: 3px;
                height: 28px;
                width: 28px;
            }

            &.blank {
                visibility: hidden;
            }
        }
    }

    .leftCol {
        display: flex;
        flex-direction: column;
        @include resp-max($lg) {
            min-width: 20px;
        }

        .body {
            white-space: nowrap;
            text-align: left;
            padding-bottom: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            @include resp-max($lg) {
                padding-bottom: 20px;
            }
            color: #464646;
        }
    }

    .leftBuffer {
        width: 100px;
        min-width: 0;
        @include resp-max($lg) {
            width: 24px;
        }
    }

    .midCol {
        display: flex;
        flex-direction: column;

        .body {
            text-align: center;
        }
    }

    .rightBuffer {
        width: 30px;
        min-width: 20px;
        @include resp-max($lg) {
            width: 20px;
            min-width: 15px;
        }
    }

    .rightCol {
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(to bottom right, rgba(237, 214, 96, 0.2), rgba(239, 164, 51, 0.2));
        border-radius: 24px;
        padding: 10px;
        margin-top: -10px;
        margin-left: -10px;

        .body {
            text-align: center;
        }

        .premium {
            background-image: linear-gradient(to bottom right, #EDD660, #EFA433);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            color: transparent;
        }
    }
}

@import '../../../scss/partials/links';

.popupContainer {
    
    // styling
    background-color: white;
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px -1px 4px 2px rgba(85, 85, 85, 0.20);
    
    // sizing
    width: 100%;
    @include py(30px, 16px);
    @include px(35px, 35px);
    max-height: 100vh;

    @include max-width($md, 0px);

    // position
    position: fixed;
    bottom: 0px;
    left:0;
    justify-content:center;

    flex-direction:column;

    display: flex;

    &.noPadding {
        padding: 0;
    }
}
@import "../../../scss/partials/links";

.CompleteWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.spacingContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.shareBox {
    width: calc(100% - 30px);
    @include mx(15px, 15px);
    padding: 24px;

    @include max-width($sm, 30px);

    display: flex;
    flex-direction: column;
    gap: 16px;

    /* No drop */
    box-shadow: 0px 0px 5px 1px rgba(77, 77, 77, 0.15);
    border-radius: 25px;
}

.meetingInfo {
    @include my(16px, 40px);
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: calc(100% - 112px);
    @include mx(56px, 56px);
    gap: 8px;

    @include resp($sm + 112px) {
        max-width: $sm;
        @include mx(calc((100% - $sm) / 2), calc((100% - $sm) / 2));
    }
}

.completedText {
    @include text(14px);
    color: $light-gray-letter;
    font-weight: 500;
    line-height: 100%;
}

.doneButton {
    @include text(16px);
    width: 160px;
    height: 40px;
    background-color: $dark-green;
    border-radius: 20px;
    box-shadow: 0px 1px 5px 1px rgba(77, 77, 77, 0.15);
    border: none;
    color: white;

    transition: background-color 0.2s;
}

.doneButton:hover {
    background-color: darken($dark-green, 5%);
}

.doneButton:active {
    background-color: darken($dark-green, 15%);
}

.shareTitle {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include text(16px);
}

.titleRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 24px;

    .title {
        @include text(20px);
    }

    .successIcon {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-size: 40px;
        color: $dark-green;
    }
}
@import '../../../scss/partials/links';

.searchBar {

    // sizing
    width: 100%;
    height: 40px;

    // internal positioning
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include px(16px, 16px);
    @include py(12px, 12px);
    box-sizing: border-box;

    // styling
    border-radius: 100px;
    border: 1px solid transparent;
    background: var(--main-text-box-fill, #F5F5F5);
    transition: border-color 0.2s;

    svg {
        font-size: 16px;
        margin-right: 12px;
        color: $text-primary;
    }

    .searchInput {
        height: 100%;
        width: 100%;
        appearance: none;
        outline: none;
        border: none;
        background: none;
        color: $text-primary;

        &::placeholder {
            color: $text-secondary;
        }
    }

    &:focus-within  {
        border: 1px solid var(--main-green, #3EA680);
    }
}
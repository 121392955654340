@import "../../../scss/partials/links";

.subtitle {
    @include text(12px);
    color: $light-gray-letter;
    text-align: center;
    font-weight: 400;
    margin: 0;
    margin-top: -10px;
}

.availabilityCardContainer {
    display: flex;

    width: calc(100% - 84px);
    @include mx(42px, 42px);
    @include max-width(600px, 84px);
}
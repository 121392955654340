@import "./mixing";
@import "./variable";
$screenList: (
    xs: $xs,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    xxl: $xxl,
    mxl: $mxl,
);

// generate padding / margin
@each $name, $screenSize in $screenList {
    @include resp($screenSize) {
        .p-#{$name}-16 {
            padding: 16px !important;
        }
        .p-#{$name}-32 {
            padding: 32px !important;
        }
        .px-#{$name}-16 {
            @include px(16px!important, 16px!important);
        }
        .px-#{$name}-32 {
            @include px(32px!important, 32px!important);
        }
        .py-#{$name}-16 {
            @include py(16px!important, 16px!important);
        }
        .py-#{$name}-32 {
            @include py(32px!important, 32px!important);
        }
        .ps-#{$name}-16 {
            padding-left: 16px !important;
        }
        .ps-#{$name}-32 {
            padding-left: 32px !important;
        }
        .pe-#{$name}-16 {
            padding-right: 16px !important;
        }
        .pe-#{$name}-32 {
            padding-right: 32px !important;
        }
        .pt-#{$name}-16 {
            padding-top: 16px !important;
        }
        .pt-#{$name}-32 {
            padding-top: 32px !important;
        }
        .pb-#{$name}-16 {
            padding-bottom: 16px !important;
        }
        .pb-#{$name}-32 {
            padding-bottom: 32px !important;
        }

        .m-#{$name}-16 {
            margin: 16px !important;
        }
        .m-#{$name}-32 {
            margin: 32px !important;
        }
        .mx-#{$name}-16 {
            @include mx(16px!important, 16px!important);
        }
        .mx-#{$name}-32 {
            @include mx(32px!important, 32px!important);
        }
        .my-#{$name}-16 {
            @include my(16px!important, 16px!important);
        }
        .my-#{$name}-32 {
            @include my(32px!important, 32px!important);
        }
        .ms-#{$name}-16 {
            margin-left: 16px !important;
        }
        .ms-#{$name}-32 {
            margin-left: 32px !important;
        }
        .me-#{$name}-16 {
            margin-right: 16px !important;
        }
        .me-#{$name}-32 {
            margin-right: 32px !important;
        }
        .mt-#{$name}-16 {
            margin-top: 16px !important;
        }
        .mt-#{$name}-32 {
            margin-top: 32px !important;
        }
        .mb-#{$name}-16 {
            margin-bottom: 16px !important;
        }
        .mb-#{$name}-32 {
            margin-bottom: 32px !important;
        }
    }
}

// generate text
@each $name, $screenSize in $screenList {
    @include resp($screenSize) {
        .text-#{$name}-20 {
            @include text(20px!important);
        }
        .text-#{$name}-24 {
            @include text(24px!important);
        }
    }
}

// center
%center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
}

%text-24{
    @include text(24px);
    color: $dark-gray;
}

%text-22 {
    @include text(22px);
    color: $dark-gray;
}

%text-20 {
    @include text(20px);
    color: $dark-gray;
}

%text-18 {
    @include text(18px);
    color: $dark-gray;
}

%text-16 {
    @include text(16px);
    color: $dark-gray;
}
%text-12 {
    @include text(12px);
    color: $dark-gray;
}

%text-10 {
    @include text(10px);
    color: $dark-gray;
}

%shadow-card {
    width: 100%;
    box-shadow: $shadow-sm;
    border-radius: 25px;
    padding: 16px;
    @include resp($lg) {
        box-shadow: $shadow-lg;
        padding: 32px;
    }
}

%cus-btn {
    background-color: $dark-purple;
    border-radius: 50px;
    height: 40px;
    width: 100%;
    min-width: 150px;
    color: white;
    @include text(15px);
    border: none;
    &:hover {
        background-color: $dark-purple;
        color: white;
    }
    .icon {
        font-weight: bold;
    }
}

%scroll-bar {
    @include resp($lg) {
        &::-webkit-scrollbar {
            width: 6px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
        }
    }
}

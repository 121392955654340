@import '../../scss/partials/links';

.viewButton {
    height: 44px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    pointer-events: auto;

    @include text(16px);
    font-weight: 700;
    @include py(14px, 14px);
    @include px(32px, 32px);
    border-radius: 100px;
    box-shadow: 0px 0px 2px 1px rgba(55, 55, 55, 0.15);

    cursor: pointer;
    transition: background-color 0.2s;

    margin-top: 8px;
    margin-bottom: 8px;
    background-color: $dark-black;
    color: white;

    &:hover {
        background-color: darken($dark-black, 5%);
    }

    &:active {
        background-color: darken($dark-black, 10%);
    }

    .text {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        text-align: center;
        width: 100%;
    }
}
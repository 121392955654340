@import '../../scss/partials/links';

.columnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    .titleText {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6px;

        margin-bottom: 20px;

        @include text(16px);

        img {
            height: 16px;
        }
    }

    .largeButtonContainer {
        width: 100%;

        .largeButton {
            width: 100%;
            height: 44px;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            @include text(16px);
            @include py(14px, 14px);
            @include px(20px, 20px);
            border-radius: 100px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

            cursor: pointer;
            transition: background-color 0.2s;
        }

        .guestModeButton {
            margin-top: 16px;
            background-color: $dark-black;
            color: white;

            &:hover {
                background-color: darken($dark-black, 5%);
            }

            &:active {
                background-color: darken($dark-black, 10%);
            }
        }
    }

    .orRow {
        display: flex;
        flex-direction: row;
        width: 80%;
        align-items: center;
        justify-content: center;
        gap: 4px;
        @include my(16px, 16px);

        .line {
            width: 100%;
            height: 1px;
            background-color: $light-gray;
        }

        .orText {
            @include text(14px);
            color: $light-gray;
        }
    }

    .manualModeSwitch {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @include text(16px);
        color: $button-secondary;
        cursor: pointer;

        transition: text-decoration 0.2s, color 0.2s;

        &:hover {
            text-decoration: underline;
            color: darken($button-secondary, 10%);
        }

        &:active {
            text-decoration: underline;
            color: darken($button-secondary, 20%);
        }
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 16px;

        .inputRow {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 16px;

            .col {
                width: 100%;
                gap: 16px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
        }

        .input {
            width: 100%;
            height: 40px;
            outline: none;
            border: 2px solid transparent;
            border-radius: 100px;
            
            background-color: $light-gray-btn;
            @include text(12px);
            color: black;

            padding-left: 20px;
            transition: border-color 0.2s;

            &::placeholder {
                color: $light-gray-letter;
            }

            &.invalid:not(:focus) {
                border-color: $dark-red;
            }

            &:focus {
                border-color: $dark-green;
            }
        }

        .formFeedback {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: -10px;
            margin-left: 16px;

            @include text(12px);
            color: $dark-red;
            font-weight: 500;
        }

        .formBottomRow {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            width: 100%;

            .forgotPasswordButton {
                @include text(12px);
                color: $light-gray;

                cursor: pointer;
                transition: text-decoration 0.2s, color 0.2s;

                margin-left: 6px;

                &:hover {
                    text-decoration: underline;
                    color: darken($light-gray, 10%);
                }

                &:active {
                    text-decoration: underline;
                    color: darken($light-gray, 20%);
                }
            }

            .spacer {
                flex-grow: 1;
            }
        }

        .recaptchaContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    .manualSwitchRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;

        margin-top: 20px;

        @include text(12px);
        color: $text-secondary;

        .manualSwitchRowButton {
            color: $text-primary;
            cursor: pointer;
            transition: color 0.2s, text-decoration 0.2s;
            text-decoration: underline;

            &:hover {
                color: darken($text-primary, 10%);
                text-decoration: none;
            }

            &:active {
                color: darken($text-primary, 20%);
                text-decoration: none;
            }
        }
    }
    
    .topBar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        .backButton {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 20px;
            width: 20px;
            color: $text-secondary;

            &:hover {
                color: darken($text-secondary, 10%);
            }

            &:active {
                color: darken($text-secondary, 20%);
            }
        }

        .guestTitleText {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            @include text(16px);
            color: $text-primary;
            flex-grow: 1;
        }

        .flexSpacer {
            width: 20px;
        }
    }

    .formAlertContainer {
        width: 100%;
    }

    .loadingSpinnerContainer {
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 24px 24px 0 0;
    }
}
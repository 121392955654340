@import '../../scss/partials/links';

.noFooterMain {
  padding-bottom: 70px;
  padding-top: 40px;

  overflow: scroll;

  @include resp($sm) {
    padding-top: 50px;
  }

  @include resp($lg) {
    padding-top: 60px;
  }

  @include resp($xl) {
    padding-top: 75px;
  }
}

.topIconBar {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 32px;

  @include mx(15px, 15px);
  width: calc(100% - 30px);
  @include max-width($sm, 30px);

  /* 
  * 'position: relative;' is set to establish a new positioning context for its child elements. 
  * This allows any child element with 'position: absolute;' to position itself relative to this parent, 
  * rather than the viewport or the nearest ancestor with a non-static position. 
  * Do not remove this line, as it ensures that the menuContainer dropdown is positioned relative to the
  * topIconBar, rather than the viewport.
  * Why does it work like that? No idea. But it does.
  * To be clear, this is not set because the position of the topIconBar is being offset in any way.
  * topIconBar retains it's default position as if it were set to static.
  */
  position: relative;

  .iconButton {
      font-size: 24px;
      color: $light-gray;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: color 0.2s;
  }

  .iconButton:hover {
      color: lighten($dark-gray, 10%);
  }

  .iconButton:active {
      color: black;
  }

  .backButton svg {
      scale: 1.2;
  }

  .menuContainer {
      width: 120px;
      position: absolute;
      // right: 84px;
      // bottom: 10px;
      right: 40px;
      top: 30px;
      z-index: 100;
      color: black;
      transition: none;
  }

  svg {
      position: static;
  }

  .deleteOption {
      color: $dark-red;
  }

  .spacer {
      flex-grow: 1;
  }
}

.titleBubbleContainer {
  margin-top: 12px;
  @include mx(15px, 15px);
  width: calc(100% - 30px);

  @include max-width($sm, 30px);
}

.instructionBox {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100% - 30px);
  @include mx(15px, 15px);
  @include max-width($sm, 30px);

  .instructionTitle {
    @include text(20px);
    margin-top: 24px;
  }

  .instructionSubtitle {
    margin-top: 8px;
    @include text(12px);
    color: $light-gray;
  }
}

.timeInformation {
  // I don't think there's anything else currently set on z-index so i just put it to 10
  z-index: 10;
  width: 44%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .closeTransparentDisplay {
    border: none;
    position: absolute;
    top: 0%;
    right: 0%;
    transform: translate(+50%, -50%);
    background-color:rgba(200, 200, 200);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dateInPopup {
    background-color:rgba(200, 200, 200);
    width: 50%;
    height: 20%;
    margin-right: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: black;
  }

  .sectionTitles {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16px;
  }

  .availableNames {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    height: 75px;
    overflow-y: auto;
    align-content: flex-start;

    .name {
      width: 100%;
      height: 15px;
      display: flex;
      justify-content: center;
      font-size: 12px;
    }
  }

  .unavailableNames {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    height: 75px;
    overflow-y: auto;
    align-content: flex-start;

    .name {
      width: 100%;
      height: 15px;
      display: flex;
      justify-content: center;
      font-size: 12px;
    }
  }
}

.availabilityCardContainer {
  width: calc(100% - 84px);
  @include max-width(400px, 84px);
  @include mx(42px, 42px);
}

.nextButtonContainer {
  width: calc(100% - 60px);
  @include mx(30px, 30px);
  display: flex;
  justify-content: flex-end;

  pointer-events: none;

  position: absolute;
  bottom: 30px;

  // @include resp($sm) {
  //     bottom: 158px;
  // }

  @include max-width($sm, 60px);

  // @include resp($lg) {
  //     bottom: 98px;
  // }
}

.nextButton {
  width: 100px;
  height: 44px;

  background-color: $light-gray-letter;
  border-radius: 22px;
  border-style: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  pointer-events: all;

  box-shadow: 0px 0px 4px 2px rgba(85, 85, 85, 0.1);
}

.nextButton.active {
  background-color: $dark-purple;
  transition: background-color 0.2s;

  box-shadow: 0px 0px 4px 2px transparentize($dark-purple, 0.8);
}

.nextButton.active:hover {
  background-color: darken($dark-purple, 5%);
}

.buttonText {
  @include text(16px);
  color: white;
  font-weight: 600;
}

.buttonText.withArrow {
  position: relative;
  left: 4px;
}

.rightArrow {
  color: white;
  position: relative;
  left: 4px;
}
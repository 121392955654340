@import "../../../scss/partials/links";

.container {
    display: flex;
    flex-direction: column;
    @include resp-max($lg) {
        text-align: center;
        justify-content: center;
        width: 66%;
        margin: auto;
    }

    .title {
        @include text(32px);
        @include resp-max($lg) {
            @include text(20px);
            line-height: 40px;
            margin-bottom: 18px;
        }
        font-weight: 600;
        text-decoration: underline;
        text-decoration-color: #3EA680;
        text-decoration-thickness: 0.2em;
        text-underline-offset: 0.6em;
        color: #464646;
        margin-bottom: 44px;
    }

    .body {
        @include text(24px);
        font-weight: 500;
        color: #A1A1A1;
        white-space: pre-line;
        @include resp-max($lg) {
            @include text(16px);
            font-weight: 500;
            max-width: 90%;
            margin-left: 5%;
        }
        @include resp($lg) {
            &.textCenter {
                max-width: 75%;
                margin-left: 12.5%;
            }
        }
    }

    &.fullWidth {
        width: 100%;
    }

    &.textCenter {
        text-align: center;
        justify-content: center;
        margin-left: 17%;
        margin-right: 17%;
    }
}
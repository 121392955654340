@import "../../../scss/partials/links";
@import "../../../scss/partials/mixing";
@import "../../../scss/partials/variable";
@import "../../../scss/partials/predefine";

.contentCard {
    width: calc(100% - 30px);
    height: calc(100% - 36px);
    min-height: 272px;
    @include mx(15px, 15px);

    display: flex;
    flex-direction: column;

    @include resp($sm + 30px) {
        max-width: $sm;
        @include mx(calc((100% - $sm) / 2), calc((100% - $sm) / 2));
    }

    @include resp($lg) {
        height: calc(100% - 46px);
    }
}

.contentCard.invalid {
    height: calc(100% - 60px);
}
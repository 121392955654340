@import "../../scss/partials/mixing";
@import "../../scss/partials/variable";
@import "../../scss/partials/predefine";

/* Mobile first design */
/* This means that initial styles are written for mobile and media queries are used
to modify those styles for desktop. */

.containerWrap {
    /* Main container and page positioning */
    width: 100%;
    height: 100%;
    padding-top: 32px;
    padding-bottom: 152px;
    overflow: scroll;

    @include mx(auto, auto);

    @include resp($lg) {
        padding-top: 112px;
    }
}

.containerWrap.noBottomPadding {
    padding-bottom: 0px;
}

.title {
    /* Title of the page */
    @include text(20px);
    text-align: center;
    margin-bottom: 16px;
    color: $dark-gray;
    @include mx(15px, 15px);
}

.nextButtonContainer {
    width: calc(100% - 30px);
    @include mx(15px, 15px);
    display: flex;
    justify-content: flex-end;

    pointer-events: none;

    position: absolute;
    bottom: 148px;

    @include resp($sm) {
        bottom: 158px;
    }

    @include resp($md + 30) {
        max-width: $md;
        @include mx(calc((100% - $md) / 2), calc((100% - $md) / 2))
    }

    @include resp($lg) {
        bottom: 98px;
    }
}
@import '../../scss/partials/variable';
@import '../../scss/partials/mixing';

.submitBtn {
  background-color: $dark-green!important;
  color: white;
  @include px(20px, 20px);
  border: none;
  display: inline-block;
  width: initial!important;
  min-width: initial!important;
}

.submit-wrap {
  margin-top: 20px;
}

.invalid {
  // box shadow that looks like border
  box-shadow: 0px 0px 0px 1px red !important;
  appearance: none;
  -webkit-appearance: none;
}
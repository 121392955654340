@import "../../../scss/partials/links";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 24px;
    @include resp-max($lg) {
        padding-top: 16px;
    }
    text-align: center;

    .image {
        width: 100px;
        height: 100px;
        @include resp-max($lg) {
            width: 70px;
            height: 70px;
        }
        border-radius: 50%;
        overflow: hidden;
        margin: auto;
        background-color: #A1A1A1;
        object-fit: cover;
    }

    .name {
        @include text(24px);
        @include resp-max($lg) {
            @include text(20px);
        }
        color: #464646;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .role {
        @include text(20px);
        @include resp-max($lg) {
            @include text(16px);
        }
        color: #A1A1A1;
        margin: auto;
    }
}
@import '../../scss/partials/links';

.customMain {
    position: relative;
    z-index: 2;
    bottom: 0;
    overflow: auto;
}

.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 90px;
    padding-right: 90px;
    @include resp-max($lg) {
        padding-left: 24px;
        padding-right: 24px;
    }
    margin-bottom: 32px;
    margin-top: 32px;

    .bufferOne {
        height: 48px;
        @include resp-max($lg) {
            height: 16px;
        }
    }
    
    .graphicsWrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
            @include resp-max($sm) {
                grid-template-columns: repeat(1, 1fr);
                column-gap: 12px;
            }
        
        .availability {
            max-height: 100%;
            max-width: 100%;
        }

        .green {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .overlapContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include resp-max($lg) {
            flex-direction: column;
            justify-content: center;
        }
        gap: 2%;

        .textAndButton {
            display: inline;

            .button {
                display: flex;
                margin-top: 24px;
                @include resp-max($lg) {
                    display: none;
                }
            }
        }

        .videoWrapper {
            display: flex;
            margin: auto;
            align-items: center;
            
            iframe {
                margin-top: 16px;
                @include resp-max($sm) {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .bufferTwo {
        height: 64px;
        @include resp-max($lg) {
            height: 32px;
        }
    }

    .differentContainer {
        .title {
            @include text(32px);
            margin-bottom: 24px;
            text-align: center;
            color: #464646;
            @include resp-max($lg) {
                @include text(20px);
                text-decoration: underline;
                text-decoration-color: #3EA680;
                text-decoration-thickness: 4px;
                text-underline-offset: 0.7em;
                margin-bottom: 36px;
            }
        }

        .boxItems {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 24px;
            @include resp-max($sm) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: 12px;
            }

            .item1 {
                span {
                    text-decoration: underline;
                    text-underline-offset: 0.2em;
                }
                line-height: 1.2em;
                margin-bottom: 10px;
                padding-left: -2.5px;
                padding-right: -2.5px;
                @include resp-max($lg) {
                    margin-bottom: 4px;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: left;   
            }

            .item2 {
                span {
                    text-decoration: underline;
                    text-underline-offset: 0.2em;
                }
                line-height: 1.2em;
                padding-left: -2.5px;
                padding-right: -2.5px;
                @include resp-max($lg) {
                    margin-bottom: 4px;
                }
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: left;
            }
        }
    }

    .premiumTitle {
        text-align: center;
        @include text(32px);
        margin-bottom: 32px;
        @include resp-max($lg) {
            @include text(20px);
            margin-bottom: 24px;
            line-height: 20px;
        }
        align-items: center;
        justify-content: center;
        color: #464646;
        img {
            width: 190px;
            @include resp-max($lg) {
                width: 110px;
            }
        }
        .premium {
            background-image: linear-gradient(to bottom right, #EDD660, #EFA433);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            color: transparent;
        }
    }

    .premiumContainer {
        background-image: linear-gradient(to bottom right, #EDD660, #EFA433);
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .missionContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include resp-max($lg) {
            flex-direction: column;
            justify-content: center;
        }
        gap: 10%;
        .graphicWrapperOne {
            @include resp-max($lg) {
                display: none;
            }
        }
    }

    .commitmentContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include resp-max($lg) {
            flex-direction: column;
            justify-content: center;
            padding-left: 0px;
        }
        gap: 10%;
        padding-left: 8%;
        .graphicWrapperTwo {
            @include resp-max($lg) {
                display: none;
            }
        }
    }

    .meetTheTeam {
        .title {
            @include text(32px);
            color: #464646;
            margin-bottom: 24px;
            text-align: center;
            text-decoration: underline;
            text-decoration-color: #3EA680;
            text-decoration-thickness: 4px;
            text-underline-offset: 0.7em;
            @include resp-max($lg) {
                @include text(20px);
                margin-bottom: 16px;
                font-weight: 600;
            }
        }

        .team {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
            @include resp-max($sm) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }


    .textAndButtonTwo {
        display: flex;
        flex-direction: column;
        gap: 24px;
        @include resp-max($lg) {
            gap: 16px;
        }
        @include resp($lg) {
            margin-left: 12%;
            margin-right: 12%;
        }
        .learn {
            margin: auto;
            background-color: #3EA680;
        }

        .donate {
            margin: auto;
            background-image: linear-gradient(to bottom right, #EDD660, #EFA433);
        }
    }

    .contactUs {
        display: flex;
        flex-direction: column;
        gap: 24px;
        @include resp-max($lg) {
            gap: 16px;
        }
        .socialMedia {
            display: flex;
            justify-content: center;
            gap: 32px;
            .socialIcons {
                height: 40px;
                width: 40px;
                @include resp-max($lg) {
                    height: 32px;
                    width: 32px;
                }
            }
        }
    }
}
@import '../../scss/partials/links';

.customMain {
    position: relative;
    z-index: 2;
    overflow: scroll;
}

.wrapperWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    .spacer {
        flex-grow: 1;
    }
}

.pageWrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    overflow: scroll;
    
    .contentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: calc(100% - 60px);

        @include max-width($md, 60px);
        @include mx(30px, 30px);
        @include py(16px, 16px);

        text-align: center;

        .title {
            @include text(64px);
            color: $dark-green;
            font-weight: 800;
        }

        .subtitle {
            @include text(40px);
            color: $dark-green;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .message {
            @include text(24px);
            color: $dark-green;
            font-weight: 500;
            margin-top: 16px;
        }

        .additionalInfoCard {
            box-shadow: 0px 0px 2px 1px rgba(85, 85, 85, 0.15);
            padding: 20px;
            margin-top: 16px;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(10px);
            max-width: $sm;

            &::before {
                background-color: transparent;
                box-shadow: none;

                position: absolute;
                content: "";
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .additionalInfoTitle {
                @include text(16px);
                color: $dark-gray;
                margin-bottom: 8px;
            }

            .infoMessage {
                @include text(16px);
                font-weight: 400;
                color: $dark-gray;
                margin-bottom: 8px;
            }

            .infoMessage:last-child {
                margin-bottom: 0;
            }
        }

        .homeButton {
            @include text(16px);
            padding: 14px 20px;
            border-radius: 100px;
            background-color: $dark-purple;
            color: white;
            margin-top: 36px;
            transition: background-color 0.2s;

            &:hover {
                background-color: darken($dark-purple, 5%);
            }

            &:active {
                background-color: darken($dark-purple, 10%);
            }
        }

        .reportButton {
            @include text(16px);
            padding: 14px 20px;
            color: black;
            margin-top: 8px;
            transition: background-color 0.2s, color 0.2s;

            &:hover {
                color: $dark-gray;
            }

            &:active {
                color: lighten($dark-gray, 10%);
            }
        }

        & > * {

            position: relative;

            &::before {
                background-color: rgba(255, 255, 255, 0.6);
                box-shadow: 0px 0px 70px 10px rgba(255, 255, 255, 1);

                position: absolute;
                content: "";
                z-index: -1;
                top: -20px;
                left: -20px;
                right: -20px;
                bottom: -20px;
            }
        }
    }
}

.reportModal {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: calc(100% - 60px);
    @include mx(30px, 30px);
    @include max-width($md, 60px);

    box-shadow: 0px 0px 10px 8px rgba(85, 85, 85, 0.15);
    padding: 20px;
    margin-top: 16px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);

    .reportModalTitle {
        @include text(24px);
        color: $dark-gray;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .reportModalSubtitle {
        @include text(16px);
        color: $dark-gray;
        font-weight: 400;
        margin-bottom: 16px;

        width: calc(100% - 30px);
        @include mx(15px, 15px);
    }

    .reportModalInput {
        width: calc(100% - 30px);
        height: 160px;
        @include mx(15px, 15px);
        @include py(12px, 14px);
        @include px(18px, 18px);

        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);

        border-radius: 10px;
        border: 2px solid $light-gray;
        transition: border-color 0.2s;
        outline: none;

        @include text(16px);
        color: $dark-gray;
        font-weight: 400;
        margin-bottom: 16px;

        &:focus {
            border-color: $dark-gray;
        }
    }

    .reportModalButton {
        @include text(16px);
        padding: 14px 20px;
        border-radius: 100px;
        background-color: $dark-gray;
        color: white;
        transition: background-color 0.2s;

        &:hover {
            background-color: darken($dark-gray, 15%);
        }

        &:active {
            background-color: black;
        }
    }
}
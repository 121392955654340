@import "../../scss/partials/links";

.blinking-image {
  animation: blink 3s ease-in-out infinite;
  opacity: 1;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.centerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loadingText {
  @include text(16px);
  margin-top: -48px;
}
@import '../../../scss/partials/links';

.cardWrap {
    // internal positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 30px 16px 30px;
    gap: 16px;

    // sizing
    width: calc(100% - 30px);
    @include mx(15px, 15px);
    @include max-width(calc($sm - 150px), 30px);

    // styling
    border-radius: 25px;
    background: white;
    box-shadow: 0px 1px 4px 2px rgba(85, 85, 85, 0.1);

    .topRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .backButton {
            font-size: 26px;
            width: 30px;

            display: inline-flex;
            justify-content: center;
            align-items: center;

            color: $light-gray;
            transition: color 0.2s;

            &:hover {
                color: darken($light-gray, 10%);
            }

            &:active {
                color: $dark-gray;
            }
        }

        .qrTitle {
            @include text(20px);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .spacer {
            width: 30px;
        }
    }

    .shareTitle {
        @include text(20px);
    }

    .qrContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        margin: -16px;

        img {
            width: 100%;
        }
    }

    .cancelButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        @include px(16px, 16px);
        @include my(4px, 8px);
        @include text(16px);
        color: $button-secondary;
        transition: color 0.2s;

        cursor: pointer;

        &:hover {
            color: darken($button-secondary, 10%);
        }

        &:active {
            color: darken($button-secondary, 30%);
        }
    }
}
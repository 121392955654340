.fullPageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.northwest {
    justify-content: flex-start;
    align-items: flex-start;
}

.north {
    justify-content: center;
    align-items: flex-start;
}

.northeast {
    justify-content: flex-end;
    align-items: flex-start;
}

.west {
    justify-content: flex-start;
    align-items: center;
}

.center {
    justify-content: center;
    align-items: center;
}

.east {
    justify-content: flex-end;
    align-items: center;
}

.southwest {
    justify-content: flex-start;
    align-items: flex-end;
}

.south {
    justify-content: center;
    align-items: flex-end;
}

.southeast {
    justify-content: flex-end;
    align-items: flex-end;
}
@import "../../../scss/partials/links";

.dropDownContainer {
    width: 100%;
    box-shadow: 0px 2px 4px 2px rgba(85, 85, 85, 0.1);
    border-radius: 20px;

    background-color: white;

    display: flex;
    flex-direction: column;

    overflow: hidden;
}
@import "../../scss/partials/links";

.header.no-mobile {
    @include resp-max($lg) {
        display: none;
    }
}

.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: white;
    box-shadow: 0px 0.5px 5px 1px rgba(76, 76, 76, 0.15);
    z-index: 100;
    @include resp($sm) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    @include resp($xxl) {
        box-shadow: 0px 1px 5px 1px rgba(77, 77, 77, 0.15);
    }

    nav {
        @include resp($xl) {
            // height: 135px;
        }
        @include resp($xxl) {
            padding-left: 70px !important;
            padding-right: 70px !important;
        }
    }
    .brand_logo {
        width: 40px;
        @include resp($sm) {
            width: 52px;
        }
    }
    // navbar brand
    .navbar-brand {
        width: 130px;
        text-align: right;
        @include resp($sm) {
            width: 152px;
        }
        @include resp($xxl) {
            width: 200px;
        }
        @include resp($mxl) {
            width: 252px;
        }
    }
    // navbar
    .navbar {
        z-index:6000;

        .navbar-nav {
            @include resp($lg) {
                column-gap: 40px;
            }
        }
        .nav-item {
            a {
                @include text(20px);
            }
        }
    }

    .options {
        @include resp($lg) {
            display:none;
        }

        .menu {
            height: 24px;
            width: 24px;
            cursor: pointer;
            position:fixed;
            right:16px;
            top:18px;
            @include resp($sm) {
                top:24px;
            }
        }
    }
}

.rightPopup {
    .insidemenu {
        height: 24px;
        width: 24px;
        cursor: pointer;
        position:fixed;
        right:16px;
        top:18px;
        @include resp($sm) {
            top:24px;
        }
    }

    .nav-link {
        margin-bottom: 16px;
    }
}
@import "../../../scss/partials/links";

.boxContainer {
    border-style: solid;
    border-width: 3px;
    border-radius: 12px;
    border-color: #3EA680;
    background-color: #F5F5F5;
    @include text(24px);
    text-align: center;
    padding: 22px 32.5px 22px 32.5px;
    @include resp-max($lg) {
        padding-left: 16px;
        padding-right: 16px;
        border-width: 2px;
    }

    .title {
        color: #464646;
        font-weight: 600;
        @include resp-max($lg) {
            @include text(20px);
        }
        margin-bottom: 10px;
    }

    .childrenContainer {
        color: #A1A1A1;
        font-weight: 400;
        @include resp-max($lg) {
            @include text(16px);
            font-weight: 400;
        }
    }
}
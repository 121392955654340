@import "../../scss/partials/links";


.noFooterMain {

    padding-bottom: 70px;
    padding-top: 40px;

    overflow: scroll;

    @include resp($sm) {
        padding-top: 50px;
    }

    @include resp($lg) {
        padding-top: 60px;
    }

    @include resp($xl) {
        padding-top: 75px;
    }
}

.pageWrapper {

    // internal positioning
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    // external positioning
    @include mx(15px, 15px);
    @include max-width($sm, 30px);

    .backButtonRow {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 24px;

        .backButton {
            font-size: 30px;
            color: $light-gray;
            transition: color 0.2s;
            width: 40px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;

            cursor: pointer;

            &:hover {
                color: $dark-gray;
            }

            &:active {
                color: darken($dark-gray, 20%);
            }
        }

        .titleBox {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .title {
                @include text(20px);
                text-align: center;
                color: $text-primary;
            }

            .subtitle {
                @include text(12px);
                font-weight: 500;
                text-align: center;
                color: $text-secondary;
            }
        }

        .spacer {
            width: 40px;
        }
    }

    .explainerContent {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 16px;

        .explainerText {
            @include text(17px);
            color: $text-primary;
            text-align: center;
        }

        .explainerImage {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 300px;
            }
        }
    }

    .availabilityCardContainer {
        width: calc(100% - 84px);
        @include max-width(400px, 84px);
        @include mx(42px, 42px);

        // to allow the grid to be scrollable even with the button
        margin-bottom: 30px;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;

    width: calc(100% - 60px);
    @include mx(30px, 30px);
    @include max-width(424px, 60px);

    position: absolute;
    bottom: 30px;

    &.centerRight {
        justify-content: center;
        align-items: flex-end;
    }

    .reassuranceText {
        @include text(12px);
        color: $text-secondary;
        text-align: center;
        margin-bottom: 18px;
    }
}
@import '../../../scss/partials/links';

.toggleSwitch {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  & > input[type="checkbox"] {
    display: none;
    
    &:checked + i {
      background-color: $dark-green;
      &::before {
        transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
      }
      &::after {
        transform: translate3d(22px, 2px, 0);
      }
    }
  }

  i {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    transition: all 0.3s linear;

    &::before, &::after {
      content: "";
      position: absolute;
      background-color: #fff;
      border-radius: 11px;
      transition: all 0.2s ease-in-out;
    }

    &::before {
      left: 0;
      width: 42px;
      height: 22px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }

    &::after {
      left: 0;
      width: 22px;
      height: 22px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      transform: translate3d(2px, 2px, 0);
    }
  }

  &:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }
}

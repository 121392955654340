@import "../../../scss/partials/links";

.gridContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;

    .column {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex-grow: 1;
    }
}
@import "../../../scss/partials/links";

.errorContainer {
    background-color: red;
    color: white;
}

.optionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $dark-gray;

    padding: 8px 16px;

    gap: 16px;

    transition: background-color 0.2s;

    .text {
        @include text(16px);
        font-weight: 500;
    }

    .icon {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.optionContainer.disabled {
    color: $light-gray;
    filter: grayscale(1);
}

.optionContainer:not(.disabled):hover {
    background-color: $light-gray-btn;
}

.optionContainer:first-child {
    padding-top: 16px;
}

.optionContainer:last-child {
    padding-bottom: 16px;
}
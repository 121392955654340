@import "../../../scss/partials/links";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    width: 100%;

    .copyLinkIcon {
        background-color: $dark-purple;
        color: white;
    }

    .QRShareIcon {
        background-color: $dark-green;
        color: white;
    }

    .shareViaIcon {
        background-color: #7D7D7D;
        color: white;
    }

    .linkBox {
        background-color: $light-gray-btn;
        height: 40px;
        border-radius: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 19px;
        padding-right: 5px;
        overflow: hidden;

        width: calc(100% + 20px);

        .linkText {
            @include text(12px);
            background: linear-gradient(90deg, #A1A1A1 81.2%, rgba(161, 161, 161, 0) 94.55%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            white-space: nowrap;
            flex-grow: 1;
            width: 100%;
            padding-bottom: 2px;
            overflow: hidden;
        }

        .shareButton {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            height: 30px;
            background-color: $dark-purple;
            border-radius: 20px;
            border: none;
            padding: 0 10px;
        
            transition: background-color 0.2s;

            &:hover {
                background-color: darken($dark-purple, 5%);
            }

            &:active {
                background-color: darken($dark-purple, 15%) !important;
            }

            .shareIcon {
                color: white;
            }
        }
    }
}
@import "../../scss/partials/links";

.menuWrapper {

    // internal positioning
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    // external positioning
    @include px(15px, 15px);
    margin-top: 24px;
    @include max-width(400px, 30px);

    .menuOption {

        // internal positioning
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include px(24px, 24px);

        // external sizing
        width: 100%;
        height: 50px;

        // styling
        box-shadow: 0px 0px 2px 1px rgba(85, 85, 85, 0.15);
        border-radius: 200px;
        @include text(12px);
        background-color: white;
        transition: background-color 0.2s, box-shadow 0.2s;

        cursor: pointer;

        .menuOptionIcon {
            font-size: 16px;
        }
    }
}
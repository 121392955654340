@import '../../scss/partials/links';

.personContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;

    .profilePicture {
        width: 30px;
        height: 30px;

        color: $light-gray;
        margin-right: 4px;
        border-radius: 50%;
    }

    .nameContainer {
        
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: 100%;
        min-width: 0;

        .personName {
        
            color: $dark-gray;
            @include text(12px);
            font-weight: 500;
            

            max-width: 100%;

            overflow: hidden;
            text-overflow: ellipsis;
        }

        .leaderTag {
            @include text(8px);
            color: $light-gray;
            margin-top: 2px;

            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;

            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $dark-green;
            }
        }
    }

    
}

.personContainer.unavailable {

    opacity: 0.5;

    .personName {
        text-decoration: line-through;
    }
}
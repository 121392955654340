@import "../../../scss/partials/links";

.button {
    // internal positioning
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    pointer-events: auto;

    @include py(14px, 14px);
    @include px(32px, 32px);

    // sizing
    height: 44px;

    // interaction
    cursor: pointer;

    // styling
    background-color: $dark-purple;
    outline: none;
    border: none;
    border-radius: 50px;
    color: white;
    @include text(16px);
    font-weight: 600;
    transition: background-color 0.2s;
    user-select: none;
    -webkit-user-select: none;

    &.withIcon {
        // shorten side padding
        @include px(20px, 20px);
    }

    &.withShadow {
        box-shadow: 0px 0px 4px 2px rgba(85, 85, 85, 0.2);
    }

    &.darker:not(.disabled) {
        transition: none;
        background: radial-gradient(farthest-corner, #8669DF 0%, #5435B1 200%);

        &:hover {
            background: darken($dark-purple, 10%);
        }
    
        &:active {
            background: darken($dark-purple, 15%);
        }
    }

    &.disabled {
        background-color: $light-gray-letter;
        pointer-events: none;
    }

    &.fullWidth {
        width: 100%;
    }

    &.shrinkLeftPadding {
        padding-left: 8px;
    }

    &.shrinkRightPadding {
        padding-right: 8px;
    }

    .text {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        text-align: center;
        width: 100%;
    }

    .icon {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.large {
            font-size: 20px;
        }
    }

    &:hover {
        background-color: darken($dark-purple, 5%);
    }

    &:active {
        background-color: darken($dark-purple, 10%);
    }
}
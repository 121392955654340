@import '../../scss/partials/links';

.noFooterMain {
  padding-bottom: 70px;
  padding-top: 40px;

  overflow: scroll;

  @include resp($sm) {
    padding-top: 50px;
  }

  @include resp($lg) {
    padding-top: 60px;
  }

  @include resp($xl) {
    padding-top: 75px;
  }

  .pageWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .topIconBar {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 32px;

    @include mx(15px, 15px);
    width: calc(100% - 30px);
    @include max-width($sm, 30px);
  }

  .titleBubbleContainer {
    margin-top: 12px;
    @include mx(15px, 15px);
    width: calc(100% - 30px);

    @include max-width($sm, 30px);
  }

  .instructionBox {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    width: calc(100% - 30px);
    @include mx(15px, 15px);
    @include max-width($sm, 30px);
  
    .instructionTitle {
      @include text(20px);
      margin-top: 24px;
    }
  
    .instructionSubtitle {
      margin-top: 8px;
      @include text(12px);
      color: $light-gray;
    }
  }

  .availabilityCardContainer {
    width: calc(100% - 84px);
    @include max-width(400px, 84px);
    @include mx(42px, 42px);

    // to allow the grid to be scrollable even with the button
    margin-bottom: 32px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;

    width: calc(100% - 60px);
    @include mx(30px, 30px);
    @include max-width(424px, 60px);

    position: absolute;
    bottom: 30px;
  }
}

.popupAddAvailabilityButton {
  box-shadow: 0px 0px 2px 1px rgba($color: #555555, $alpha: 0.15);
}
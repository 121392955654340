@import "./variable";

@mixin text( $size: 16px) {
    font-style: normal;
    font-weight: 600;
    font-size: $size;
    line-height: 100%;
    letter-spacing: -0.024em;
}

// margin and padding 
@mixin py ($t, $b) {
    padding-top: $t;
    padding-bottom: $b;
}
@mixin px ($l, $r) {
    padding-left: $l;
    padding-right: $r;
}
@mixin my ($t, $b) {
    margin-top: $t;
    margin-bottom: $b;
}
@mixin mx ($l, $r) {
    margin-left: $l;
    margin-right: $r;
}

// this mixing push responsive desing inside that class 
@mixin resp ($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

@mixin resp-max ($width) {
    @media only screen and (max-width: $width) {
        @content;
    }
}

@mixin resp-height ($height) {
    @media only screen and (min-height: $height) {
        @content;
    }
}

@mixin resp-max-height ($height) {
    @media only screen and (max-height: $height) {
        @content;
    }
}

@mixin max-width ($width, $margin) {
    @include resp($width + $margin) {
        width: $width;
        @include mx(calc((100% - #{$width}) / 2), calc((100% - #{$width}) / 2));
    }
}

@mixin full-width-with-margin($margin) {
    width: calc(100% - $margin);
    @include mx(calc($margin / 2), calc($margin / 2));
}
@import '../../scss/partials/links';

.pageContainer {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 30px;

    .titleText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;

        margin-bottom: 20px;

        @include text(16px);

        color: $dark-green;
        font-weight: 500;

        img {
            height: 30px;
        }
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 20px;

        .pageText {
            @include text(20px);
            text-align: center;
            @include mx(30px, 30px);
    
            &.title {
                color: $dark-green;
                margin-bottom: -20px;
            }
    
            &.message {
                font-weight: 300;
    
                b {
                    font-weight: 500;
                }
            }
        }
    }

    .imageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            max-width: 300px;
            height: auto;
        }
    }
}
@import "../../scss/partials/links";

.text {
    @include text(16px);
    text-align: center;
}

.primary {
    color: $text-primary;
}

.secondary {
    color: $text-secondary;
}

.white {
    color: white;
}
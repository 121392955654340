@import '../../../scss/partials/links';

.alertBox {

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 10px 12px;
  
    background-color: white;
    border-radius: 10px;
    border: 2px solid black;
  
    .alertText {
      @include text(14px);
    }

    .alertIcon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
    }
  }
@import '../../../scss/partials/links';


.toast {
    @include text(14px);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    border-radius: 10px;
}

.successToast {
    border: 2px solid #3EA680;
}

.errorToast {
    border: 2px solid #E1261A;
}
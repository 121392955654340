@import "../../../scss/partials/links";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 12px;

    border-radius: 100px;

    cursor: pointer;

    .iconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        padding: 6px 13px;
        border-radius: 100px;

        @include text(16px);
        transition: opacity 0.2s;
    }

    .text {
        @include text(16px);
        font-weight: 500;
        transition: color 0.2s;
    }

    .spacer {
        flex-grow: 1;
    }

    .arrowButton {
        @include text(20px);
        transition: color 0.2s;
    }

    &:hover {
        .text, .arrowButton {
            color: lighten($dark-gray, 10%);
        }

        .iconContainer {
            opacity: 0.9;
        }
    }
}
@import '../../scss/partials/links';

.topIconBar {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 32px;
    width: calc(100% - 30px);
    position: relative;
    @include mx(15px, 15px);
    @include max-width($sm, 30px);

    .iconButton {
        font-size: 24px;
        color: $light-gray;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.2s;
        cursor: pointer;
        position: relative;

        &:hover {
            color: lighten($dark-gray, 10%);
        }

        &:active {
            color: black;
        }

        svg {
            position: static;
        }
    }

    .backButton svg {
        scale: 1.2;
    }

    .menuContainer {
        width: 120px;
        position: absolute;
        right: 0;
        top: 30px;
        z-index: 100;
        color: black;
        transition: none;
        cursor: pointer;
    }

    .deleteOption {
        color: $dark-red;
    }

    .spacer {
        flex-grow: 1;
    }

    .ellipsisButton,
    .shareButton {
        &:hover {
            color: lighten($dark-gray, 10%);
        }

        &:active {
            color: black;
        }
    }
}
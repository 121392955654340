@import "../../scss/partials/links";

.Wrap {
    @include mx(15px, 15px);
    @include max-width($sm, 30px);

    margin-top: 32px;
    text-align: center;

    .PageTitle {
        @include text(20px);
        font-weight: 500;
    }

    .PageDescription {
        @include text(14px);
        font-weight: 500;
        color: $light-gray;

        @include mx(30px, 30px);
        margin-top: 8px;
    }

    .FormWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        padding: 16px 15px;
        background-color: white;
        border-radius: 25px;
        box-shadow: 0px 0px 5px 1px rgba(77, 77, 77, 0.15);

        label {
            @include text(14px);
            @include mx(10px, 10px);
        }

        input {
            outline: none;
            border: none;
            border-radius: 50px;
            background-color: #F5F5F5;
            padding: 10px 20px;
            margin-top: 10px;

            height: 40px;

            width: 100%;

            @include text(12px);
        }
    }

    .SubmitWrap {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    
        .SendEmailButton {
            border-radius: 100px;
            background-color: $dark-purple;
            transition: background-color 0.2s;
            border: none;
            padding: 14px 32px;

            @include text(16px);
            color: white;

            &:hover {
                background-color: darken($dark-purple, 5%);
            }

            &:active {
                background-color: darken($dark-purple, 10%);
            }
        }
    
        .Return {
            @include text(12px);
            color: $light-gray;
            text-decoration: underline;

            &:hover {
                color: darken($light-gray, 10%);
            }

            &:active {
                color: darken($light-gray, 20%);
            }
        }
    }
}

.checkIcon {
    margin-top: 48px;
    color: $dark-green;
    font-size: 100px;
    width: 100%;
}

@import "../../scss/partials/links";

.hidden {
    display: none;
}

.topWrap {

    // internal positioning
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    // spacing
    padding-top: 24px;
    padding-bottom: 24px;

    // drop shadow line
    box-shadow: 0px 2px 5px 1px rgba(77, 77, 77, 0.15);

    position: relative; // allows for absolute positioning of back button

    .backButton {
        font-size: 35.6px; // makes the icon exactly 22px tall, as specified in figma
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        // position in the top left corner of the container
        position: absolute;
        top: 24px;
        left: 20px;

        color: $light-gray;
        transition: color 0.2s;

        &:hover {
            color: $dark-gray;
        }

        &:active {
            color: darken($dark-gray, 20%);
        }
    }

    .profileImgContainer {

        width: 80px;
        height: 80px;

        position: relative;

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;

            position: relative;
            z-index: 1;
        }

        .editButtonContainer {
            position: absolute;
            bottom: -4px;
            right: -4px;
            z-index: 2;
            
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: $dark-green;
            transition: background-color 0.2s;
            
            display: flex;
            justify-content: center;
            align-items: center;

            .editButton {
                font-size: 18px;
                color: white;
                fill: transparent;
                transition: fill 0.2s;
            }
        }

        &:hover {
            .editButtonContainer {
                background-color: darken($dark-green, 5%);
            }
        }

        &:active {
            .editButtonContainer {
                background-color: darken($dark-green, 10%);
                .editButton {
                    fill: white;
                }
            }
        }
    }

    .userName {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        margin-top: 20px;
        @include text(20px);

        svg {
            font-size: 16px;
            transition: fill 0.2s;
            fill: transparent;
        }

        &.editMode {
            padding: 6px 12px;
            background-color: #F5F5F5;
            transition: background-color 0.2s;
            border-radius: 200px; // any large value will cause it to be a pill shape

            cursor: pointer;

            &:hover {
                background-color: darken(#F5F5F5, 5%);
            }

            &:active {
                background-color: darken(#F5F5F5, 10%);
                svg {
                    fill: black;
                }
            }
        }

        .userNameChangeInput {
            color: $dark-gray;
            border: none;
            outline: none;
            background-color: transparent;
            text-align: center;
            text-decoration: underline;

            cursor: text;
        }
    }
}
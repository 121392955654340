@import "../../scss/partials/links";

.pageWrapper {

    // internal positioning
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    // external positioning
    @include mx(15px, 15px);
    @include max-width($sm, 30px);

    margin-top: 24px;

    .backButtonRow {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .backButton {
            font-size: 35.6px;
            color: $light-gray;
            transition: color 0.2s;

            cursor: pointer;

            &:hover {
                color: $dark-gray;
            }

            &:active {
                color: darken($dark-gray, 20%);
            }
        }
    }

    .pageContent {

        // sizing
        width: 100%;

        // internal positioning
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .sectionContainer {

            // internal positioning
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            @include py(16px, 16px);
            position: relative;

            // sizing
            width: 100%;

            // styling
            border-radius: 25px;
            background-color: white;
            box-shadow: 0px 0px 2px 1px rgba(85, 85, 85, 0.15);

            z-index: 5;

            .inputGroup {

                // internal positioning
                @include px(24px, 24px);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                // sizing
                width: 100%;

                .inputLabel {
                    @include text(12px);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    .inputLabelButton {
                        @include text(12px);
                        color: $text-secondary;
                        text-decoration: underline;
                        transition: color 0.2s;

                        &:hover {
                            color: darken($text-secondary, 10%);
                            text-decoration: none;
                        }

                        &:active {
                            color: darken($text-secondary, 20%);
                            text-decoration: none;
                        }
                    }
                }
            }

            &.disabled {
                user-select: none;

                .grayedOutBox {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    opacity: 1;
                    top: 0;
                    left: 0;
                    border-radius: 25px;
                    z-index: 10;

                    backdrop-filter: brightness(95%) saturate(10%);
                    -webkit-backdrop-filter: brightness(95%) saturate(10%);
                }

                .comingSoonText {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    gap: 8px;
        
                    @include text(16px);
                    color: black;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 15;
        
                    color: $dark-gray;
                    border-radius: 25px;
        
                    opacity: 0;
                    transition: opacity 0.2s;
        
                    border: 1px solid $dark-gray;
        
                    width: 100%;
                    height: 100%;
        
                    &:hover {
                        opacity: 1;
                        backdrop-filter: blur(2px);
                        -webkit-backdrop-filter: blur(2px);
                    }
                }
            }

        }

        .fullWidthButton {
            // internal positioning
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            @include px(24px, 24px);

            // sizing
            width: 100%;
            height: 50px;

            // styling
            border-radius: 25px;
            background-color: white;
            box-shadow: 0px 0px 2px 1px rgba(85, 85, 85, 0.15);

            // text styling
            @include text(12px);
        }
    }

    .sectionDivider {
        width: calc(100% - 30px);
        max-width: ($sm - 30px, 30px);
        height: 2px;
        border-radius: 10px;
        background: #F5F5F5;
    }

    .accountDeleteButton {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        @include text(12px);
        color: #E1261A;
        transition: color 0.2s;

        &:hover {
            color: darken(#E1261A, 10%);
        }

        &:active {
            color: darken(#E1261A, 20%);
        }
    }
}
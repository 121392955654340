@import '../../../scss/partials/links';

.container {
    display: flex;
    flex-direction: row;
    width: 100%;

    align-items: center;
    justify-content: space-between;
    gap: 8px;

    @include px(24px, 24px);

    .line {
        width: 100%;
        height: 1px;
        background-color: $light-gray;

        // flex-grow: 1;
    }

    .text {
        @include text(14px);

        color: $light-gray;
    }
}
@import '../../../scss/partials/links';

.wrapperClass {

    // styling
    background-color: white;
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px -1px 4px 2px rgba(85, 85, 85, 0.20);
    
    // sizing
    width: 100%;
    max-height: 100vh;

    @include max-width($md, 0px);

    // position
    position: fixed;
    bottom: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-start;
    pointer-events: all;

    flex: 1;
    overflow: auto;

    &.expanded {
        @include py(30px, 16px);
        @include px(35px, 35px);
    }

    .collapsedContainer {
        @include px(16px, 16px);
        @include py(8px, 8px);

        .collapsedRow {
            background: $light-gray-btn;
            border-radius: 100px;
            padding: 4px;
            box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
            gap: 10px;
            padding-left: 16px;
            overflow-x: auto;
        
            .collapsedImage {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: $light-gray;
            }
        }
    }

    .clearButton {
        margin: 16px;
        pointer-events: all;
    }

    .closeButton {
        font-size: 24px;
        color: $button-secondary;
        margin: 16px;
    
        transition: color 0.2s;
        pointer-events: all;
        cursor: pointer;
    
        &:hover {
            color: $dark-gray;
        }
    }

    .personListContainer {
        flex-shrink: 1;
        overflow-y: auto;
        
        margin-top: 4px;

        .personList {
            display: grid;
            row-gap: 12px;
            column-gap: 24px;
            grid-template-columns: repeat(4, 118px);
            padding: 12px;
            
            &.limitHeight {
                max-height: 100px;
                overflow-y: auto;
            }
        
            .personContainer {
                width: 118px;
                height: 32px;
                text-overflow: ellipsis;
            }
        }

        @media (max-width: 656px) {
            .personList {
                grid-template-columns: repeat(3, 118px);
            }
        }

        @media (max-width: 512px) {
            .personList {
                grid-template-columns: repeat(2, 118px);
            }
        }

        @media (max-width: 370px) {
            .personList {
                display: flex;
                flex-direction: column;
                gap: 12px;
                width: 100%;

                .personContainer {
                    width: 100%;
                }
            }
        }
    }

    .secondaryColor {
        color: $text-secondary;
    }
}

.spacer {
    display: block;
    width: 100%;
    height: 0;
}